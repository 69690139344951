import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Box, CircularProgress } from '@mui/material';

import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { type MRT_ColumnDef } from 'material-react-table';

import { venueGetConversationWithUserApi } from '../../../api/chat/venueGetConversationWithUserApi';
import { OfferCategory, Reservation, ReservationStatus } from '../../../graphql/API';
import {
  ReservationPaymentStatus,
  ReservationPost,
} from '../../../pages/ReservationDetail/ReservationDetail';
import icons from '../../../themes/icons';
import { getDateAndTimeInTimezoneInDayjs, getTimeInTimezoneInDayjs } from '../../../utils/date';
import { checkChatHoursIsNotPassed } from '../../../utils/reservations';
import { getUserCategory } from '../../../utils/user';
import UserAvatarCategory from '../../Avatar/UserAvatarCategory/UserAvatarCategory';
import Button from '../../Button/Button';
import StatusLabel from '../../StatusLabel/StatusLabel';

type UseColumnProps = {
  detail?: boolean;
};

const CollabBadge = styled.span`
  display: inline-block;
  color: #7b7bff;
  font-size: 15px;
  font-weight: 400;
`;

const base: MRT_ColumnDef<Reservation>[] = [
  {
    accessorKey: 'userPicture.thumbnail',
    header: '',
    size: 80,
    enableSorting: false,
    Cell: ({ renderedCellValue, row }) =>
      renderedCellValue ? (
        <UserAvatarCategory
          src={String(row.original.userPicture?.thumbnail ?? '')}
          userCategory={getUserCategory(row.original.user?.tags ?? [])}
        />
      ) : null,
  },
  {
    accessorKey: 'modelName',
    accessorFn: (row) =>
      row.user?.firstname || row.user?.lastname
        ? `${row.user?.firstname} ${row.user?.lastname}`
        : undefined,
    header: 'Name',
    size: 150,
    Cell: ({ renderedCellValue, row }) => (
      <Box
        component={Link}
        to={`/users/${row.original.userID}`}
        sx={{
          textDecoration: 'none',
          ':hover': {
            textDecoration: 'underline',
          },
          color: '#1E55AA',
        }}
      >
        {renderedCellValue}
      </Box>
    ),
  },
  {
    accessorKey: 'offerTitle',
    header: 'Offer',
    size: 210,
    Cell: ({ renderedCellValue }) => (
      <Box display={'flex'} alignItems={'center'} gap={'12px'}>
        <span>{renderedCellValue}</span>
      </Box>
    ),
  },
  {
    accessorKey: 'privateOffer',
    header: 'Collab',
    size: 200,
    Cell: ({ row }) => (row.original.privateOffer ? <CollabBadge>Collab</CollabBadge> : '-'),
  },
  {
    accessorKey: 'effectiveStartDateTime',
    header: 'Date',
    size: 200,
    Cell: ({ row }) => (
      <>
        {getDateAndTimeInTimezoneInDayjs(
          row?.original?.effectiveStartDateTime as string,
          row?.original?.timeZoneId,
        )?.format('LL')}
      </>
    ),
  },
  {
    accessorKey: 'status',
    header: 'Status',
    size: 150,
    Cell: ({ renderedCellValue }) => (
      <StatusLabel type='reservation' status={String(renderedCellValue)} emptyBackground />
    ),
  },
  {
    accessorKey: 'validation',
    header: 'Posted',
    size: 150,
    Cell: ({ renderedCellValue, row }) => (
      <Box display={'flex'} alignItems={'center'} gap={'6px'}>
        {renderedCellValue === ReservationPost.validated ||
        renderedCellValue === ReservationPost.validationUpdated ? (
          <DoneIcon htmlColor='#37BB90' />
        ) : renderedCellValue === 'validationFailed' ? (
          <CloseIcon htmlColor='#DC5564' />
        ) : (
          '-'
        )}
        {row.original.postLink && (
          <Button
            variant='text'
            sx={{ textDecoration: 'underline' }}
            onClick={() => {
              window.open(row.original.postLink || '', '_blank');
            }}
          >
            Go to post
          </Button>
        )}
      </Box>
    ),
  },
];

const action: MRT_ColumnDef<Reservation> = {
  accessorKey: 'id',
  header: 'Action',
  size: 80,
  enableSorting: false,
  Cell: ({ renderedCellValue, row }) => {
    const navigate = useNavigate();
    const [isConversationCreationLoading, setIsConversationCreationLoading] = React.useState(false);

    const isChatCanStart = React.useMemo(
      () =>
        (checkChatHoursIsNotPassed(row.original) &&
          row.original?.status !== ReservationStatus.cancelled &&
          (row.original?.privateOffer ||
            (row.original.offerCategory === OfferCategory.beauty &&
              row.original.offerConfirmWithCall))) ||
        (row.original &&
          row.original?.privateOffer &&
          row.original.paymentStatus === ReservationPaymentStatus.stop) ||
        false,
      [row.original],
    );

    const createConversationWithUser = async () => {
      try {
        setIsConversationCreationLoading(true);
        await venueGetConversationWithUserApi({
          venueID: row.original.venueID || '',
          userID: row.original?.userID || '',
          reservationID: row.original.id || '',
          offerTitle: row.original.offerTitle || '',
        });
        setIsConversationCreationLoading(false);
        navigate(
          `/chats?userID=${row?.original.userID}&reservationID=${row.original?.id}&offerTitle=${row.original.offerTitle}`,
        );
      } catch (error) {
        console.error(error);
        setIsConversationCreationLoading(false);
      }
    };

    return (
      <Box display={'flex'} alignItems={'center'} gap={'8px'}>
        <Button
          component={Link}
          to={`/reservations/${renderedCellValue}`}
          variant='outlined'
          size='small'
        >
          Details
        </Button>
        {isChatCanStart && (
          <Button
            variant='contained'
            size='small'
            startIcon={
              isConversationCreationLoading ? (
                <CircularProgress size='1rem' sx={{ color: 'grey' }} />
              ) : (
                <icons.ChatIcon style={{ width: '16px', height: '16px' }} />
              )
            }
            onClick={createConversationWithUser}
            disabled={isConversationCreationLoading}
          >
            Chat
          </Button>
        )}
      </Box>
    );
  },
};

const useColumn = ({ detail }: UseColumnProps): MRT_ColumnDef<Reservation>[] => {
  const columns = React.useMemo<MRT_ColumnDef<Reservation>[]>(
    () => (detail ? base.concat(action) : base),
    [detail],
  );

  return columns;
};

export default useColumn;
