import * as React from 'react';

import { Box, useScrollTrigger } from '@mui/material';

import InfiniteScroll from '../../components/InfiniteScroll/InfiniteScroll';
import { User } from '../../graphql/API';
import useListUserQuery from '../../hooks/reactQuery/useListUserQuery';
import useVenueId from '../../hooks/useVenueId';
import Header from './Header';
import ProfileCheckBoxGroup from './components/ProfileCheckBoxGroup';
import useCollabsReducer from './reducer';

const Collabs: React.FC = () => {
  const venueId = useVenueId();
  const [state, dispatch] = useCollabsReducer();
  const { selected, filters } = state;

  const elementRef = React.useRef<HTMLDivElement>();
  const inScroll = useScrollTrigger({
    threshold: 0,
    disableHysteresis: true,
    target: elementRef.current,
  });

  const { data, isFetching, isLoading, fetchNextPage, hasNextPage } = useListUserQuery(
    venueId,
    filters,
  );

  const getSelectedUsersData = React.useCallback(
    (ssUsers: string[]) =>
      ssUsers.map((x) => data.find((y) => x === y.id)).filter(Boolean) as User[],
    [data],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'auto',
        backgroundColor: 'white',
      }}
    >
      <Header reducer={[state, dispatch]} inScroll={inScroll} />

      <Box sx={{ p: 4, pt: 0, overflowY: 'auto' }} ref={elementRef}>
        <InfiniteScroll
          isLoading={isLoading}
          isFetching={isFetching}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
        >
          <ProfileCheckBoxGroup
            data={data}
            isLoading={isLoading}
            onChange={(newSelected) => {
              const previousUsers = (state.selected.users || []).filter((x) =>
                newSelected.includes(x.id),
              );
              dispatch({
                type: 'SET_SELECTED_USERS',
                payload: {
                  values: newSelected,
                  users: Array.from(
                    new Set([...previousUsers, ...getSelectedUsersData(newSelected)]),
                  ),
                },
              });
            }}
            values={selected.values}
          />
        </InfiniteScroll>
      </Box>
    </Box>
  );
};

export default Collabs;
