import * as React from 'react';
import { AspectRatio } from 'react-aspect-ratio';
import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  ListItemAvatar,
  ListItemText,
  List as MuiList,
  ListItem as MuiListItem,
  styled as MuiStyled,
  Stack,
  Typography,
} from '@mui/material';

import dayjs from 'dayjs';
import { groupBy, isEmpty } from 'lodash';

import { Offer, OfferCategory, OfferRequirements, OfferStatus } from '../../../../graphql/API';
import { useListOfferQuery } from '../../../../hooks/reactQuery/useOfferQuery';
import useQueryParams from '../../../../hooks/useQueryParams';
import Highlight from '../../../../pages/Offer/components/Highlight';
import { datetimeWithTimezone, getTimeInTimezoneInDayjs } from '../../../../utils/date';
import Button from '../../../Button/Button';
import { useCreateInvite } from '../context/CreateInvite.context';
import ListSkeleton from './ListSkeleton';
import { OfferNotFound } from './OfferNotFound';

const showDate = (o: Offer) => {
  const { startDate, startTime, timeZoneId: timezoneId } = o;

  const sdt = datetimeWithTimezone({
    date: startDate,
    time: startTime,
    timezoneId,
  }).tz();

  return sdt.format('ll');
};

const showTimeRange = (o: Offer) => {
  const { startDate, startTime, endDate, endTime, timeZoneId: timezoneId } = o;

  const sdt = datetimeWithTimezone({
    date: startDate,
    time: startTime,
    timezoneId,
  }).tz();

  const eDt = datetimeWithTimezone({
    date: endDate ?? startDate,
    time: endTime,
    timezoneId,
  }).tz();

  return `${sdt.format('LT')} - ${eDt.format('LT')}`;
};

const List = MuiStyled(MuiList)(({ theme }) => ({
  '& > .MuiListItem-root:not(:first-of-type)': {
    marginTop: theme.spacing(1),
  },
}));

const ListItem = MuiStyled(MuiListItem)(({ theme }) => ({
  padding: theme.spacing(0.25),
  borderRadius: theme.spacing(1),
  alignItems: 'flex-start',
  '.MuiListItemAvatar-root': {
    width: theme.spacing(37.5),
  },
  '.MuiListItemText-root': {
    marginLeft: theme.spacing(4),
  },
  '.MuiListItemSecondaryAction-root': {
    top: 'unset',
    transform: 'unset',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

const SelectedButton = MuiStyled(Button)({
  padding: '12px 48px',
});

const offerGroupByPrivate = (data: Offer[]) => {
  const filteredData = data.filter((x) => x.private || x.category === OfferCategory.event);
  return groupBy(filteredData, (x) => (x.private ? 'private' : 'event'));
};

type OfferListProps = {
  type: 'collab' | 'special event';
  data: Offer[];
  onClick: (value: string) => void;
};

const OfferList: React.FC<OfferListProps> = ({ type, data, onClick }) => {
  const handleOnClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      onClick(e.currentTarget.value);
    },
    [onClick],
  );

  return data.length ? (
    <Box>
      <List sx={{ mt: 1 }}>
        {data.map((x) => (
          <ListItem key={x.id}>
            <ListItemAvatar sx={{ position: 'relative' }}>
              <Highlight
                privateOffer={x.private || false}
                eventOffer={x.category === OfferCategory.event}
                sx={{ zIndex: 99 }}
              />
              <AspectRatio ratio='16/9'>
                <Avatar
                  sx={(theme) => ({ borderRadius: theme.spacing(1) })}
                  variant='rounded'
                  src={String(x.picture?.medium)}
                />
              </AspectRatio>
            </ListItemAvatar>
            <ListItemText
              sx={{
                marginLeft: '34px',
              }}
              primary={
                <Typography fontSize='15px' fontWeight='bold' marginBottom='10px'>
                  {x.title}
                </Typography>
              }
              secondary={
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  gap={type !== 'collab' ? '35px' : '5px'}
                  alignItems={'flex-start'}
                >
                  <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                    {type !== 'collab' && (
                      <Typography fontSize='14px' variant='caption' fontWeight='medium'>
                        {showDate(x)}
                      </Typography>
                    )}

                    {type !== 'collab' && (
                      <Typography fontSize='14px' variant='caption' fontWeight='medium'>
                        {showTimeRange(x)}
                      </Typography>
                    )}
                  </Box>

                  <SelectedButton
                    id={`btn-offer-${x.id}`}
                    variant='contained'
                    value={x.id}
                    onClick={handleOnClick}
                  >
                    Select
                  </SelectedButton>
                </Box>
              }
            />
          </ListItem>
        ))}
      </List>
    </Box>
  ) : null;
};

const Step1: React.FC = () => {
  const navigate = useNavigate();
  const { getQueryParam } = useQueryParams();
  const collabId = getQueryParam('collabId');

  const { data, isLoading } = useListOfferQuery({ status: OfferStatus.active });
  const { dispatch } = useCreateInvite();

  const handleSubmit = React.useCallback(
    (value: string) => {
      const found = data.find((x) => x.id === value);

      if (found) {
        if (found.category === OfferCategory.event) {
          const startDateTime = getTimeInTimezoneInDayjs(found.startTime, found.timeZoneId);
          const endDateTime = getTimeInTimezoneInDayjs(found.endTime, found.timeZoneId);

          dispatch({
            type: 'next',
            payload: {
              offer: found,
              dates: [dayjs(found.startDate)],
              startTime: startDateTime,
              endTime: endDateTime,
              requirements: OfferRequirements.postOnInstagramStories,
            },
          });
        } else {
          dispatch({
            type: 'next',
            payload: {
              offer: found,
              requirements: OfferRequirements.postOnInstagramStories,
            },
          });
        }
      }
    },
    [data, dispatch],
  );

  const dataGroup = React.useMemo(() => offerGroupByPrivate(data), [data]);

  React.useEffect(() => {
    if (!isLoading) {
      dispatch({ type: 'update', payload: { isEmptyOffer: !data.length } });
    }
  }, [isLoading, data, dispatch]);

  const createOfferHandle = React.useCallback(() => {
    navigate('/offers/create?type=collab');
  }, [navigate]);

  const collabOffers =
    collabId && dataGroup.private
      ? dataGroup.private.filter((offer) => offer.id == collabId)
      : dataGroup.private || [];

  const eventOffers =
    collabId && dataGroup.event
      ? dataGroup.event.filter((offer) => offer.id == collabId)
      : dataGroup.event || [];

  return (
    <Box
      mt={6}
      sx={{
        maxHeight: 'calc(600px - 170px)',
        overflowY: 'auto',
      }}
    >
      <Stack spacing={3}>
        {isLoading ? (
          <ListSkeleton />
        ) : (
          <>
            {!isEmpty(dataGroup) &&
            dataGroup &&
            (collabOffers?.length > 0 || eventOffers?.length > 0) ? (
              <>
                <OfferList type='collab' data={collabOffers} onClick={handleSubmit} />
                <OfferList type='special event' data={eventOffers} onClick={handleSubmit} />
              </>
            ) : (
              <OfferNotFound onCreateOffer={createOfferHandle} />
            )}
          </>
        )}
      </Stack>
    </Box>
  );
};

export default Step1;
