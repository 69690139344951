import * as React from 'react';

import { useQueryClient } from '@tanstack/react-query';
import JsFileDownloader from 'js-file-downloader';
import { ShowFnOutput, useModal } from 'mui-modal-provider';

import { venueDownloadReservationContentApi } from '../../../../api/reservations/venueDownloadReservationContentApi';
import { venueDownloadReservationContentV2Api } from '../../../../api/reservations/venueDownloadReservationContentV2Api';
import { venueGetUserApi } from '../../../../api/users/venueGetUserApi';
import { VenueReservationContentItemV2 } from '../../../../graphql/API';
import { fetchAccountBalance } from '../../../../redux/features/balance/balanceSlice';
import { updateCredit } from '../../../../redux/features/venue/venueSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { ConfirmAction } from '../../Confirm/context/types';
import InviteDialog, { InviteDialogProps } from '../../CreateInvite/InviteDialog';
import SaveConfirmation from './SaveConfirmation';

type LoadingState = Record<string, boolean>;

const getLoadingKey = (
  action: 'saveContent' | 'downloadContent' | 'saveUser' | 'invite',
  id: string,
) => `${action}:${id}`;

type GetLoadingKeyParam = Parameters<typeof getLoadingKey>;
type UseActionProps = {
  itemId: number;
  items: VenueReservationContentItemV2[];
  confirm: ConfirmAction;
};

export type UseActionResponse = ReturnType<typeof useAction>;

const useAction = ({ itemId, items, confirm }: UseActionProps) => {
  const [item, setItem] = React.useState(items.find((x) => x.id === itemId));
  const [loading, setLoading] = React.useState<LoadingState>({});

  const venueState = useAppSelector((state) => state.venue.value);
  const appDispatch = useAppDispatch();
  const { showModal } = useModal();

  const queryClient = useQueryClient();

  const inviteModalRef = React.useRef<ShowFnOutput<InviteDialogProps>>();

  React.useEffect(() => {
    if (item && items) {
      const i = items.find((x) => x.id === item.id);
      setItem(i);
    }
  }, [items, item]);

  const handleNavigation = React.useCallback(
    (nav: 'prev' | 'next') => {
      const ci = items.findIndex((x) => x.id === item?.id);
      let nextIndex = (nav === 'next' ? ci + 1 : ci - 1) % items.length;
      nextIndex = nextIndex >= 0 ? nextIndex : items.length + nextIndex;

      const nextItem = items[nextIndex];
      setItem(nextItem);
    },
    [items, item?.id],
  );

  React.useEffect(() => {
    const onKeyPress = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowLeft':
          handleNavigation('prev');
          break;
        case 'ArrowRight':
          handleNavigation('next');
          break;
      }
    };

    window.addEventListener('keydown', onKeyPress);

    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [handleNavigation]);

  const getLoading = React.useCallback(
    (action: GetLoadingKeyParam[0], id: GetLoadingKeyParam[1]) =>
      loading[getLoadingKey(action, id)],
    [loading],
  );

  const handleInviteUser = React.useCallback(async () => {
    const u = item?.user;

    if (!(u && venueState?.id)) {
      return;
    }

    const loadingKey = getLoadingKey('invite', String(item.id));
    setLoading((x) => ({ ...x, [loadingKey]: true }));

    const user = await venueGetUserApi({ venueID: Number(venueState.id), userID: Number(u.id) });
    setLoading((x) => ({ ...x, [loadingKey]: false }));

    inviteModalRef.current = showModal(InviteDialog, {
      title: 'Invite',
      users: [user],
      modalRef: inviteModalRef,
      onSuccess: () => {},
    });
  }, [showModal, item, venueState?.id]);

  const handleSaveContent = React.useCallback(async () => {
    const credits = item?.downloadCredits ?? 0;

    const isConfirm = await confirm({
      title: '',
      confirmationText: 'Confirm',
      description: (
        <SaveConfirmation item={item} venueCredit={venueState?.availableDownloads ?? 0} />
      ),
      dialogProps: { height: 'unset' },
    });

    if (isConfirm && item?.id) {
      const loadingKey = getLoadingKey('saveContent', String(item.id));

      setLoading((x) => ({ ...x, [loadingKey]: true }));
      await venueDownloadReservationContentApi({ id: String(item?.id) });

      await queryClient.invalidateQueries({ queryKey: ['list-reservation-content'] });

      setLoading((x) => ({ ...x, [loadingKey]: false }));

      appDispatch(updateCredit(-credits));
    }
  }, [confirm, item, venueState?.availableDownloads, queryClient, appDispatch]);

  const handleBuyContent = React.useCallback(async () => {
    if (item && item?.id) {
      const loadingKey = getLoadingKey('saveContent', String(item.id));

      setLoading((x) => ({ ...x, [loadingKey]: true }));
      await venueDownloadReservationContentV2Api({ id: String(item?.id) });

      await queryClient.invalidateQueries({ queryKey: ['list-reservation-content'] });

      setLoading((x) => ({ ...x, [loadingKey]: false }));

      appDispatch(fetchAccountBalance({ venueID: venueState?.id || '' }));
    }
  }, [item, queryClient, appDispatch, venueState?.id]);

  const handleSaveUser = React.useCallback(async () => {
    queryClient.invalidateQueries({ queryKey: ['list-reservation-content'] });
  }, [queryClient]);

  const handleDownloadContent = React.useCallback(async () => {
    const url = item?.images?.video ?? item?.images?.large;
    if (url) {
      await new JsFileDownloader({ url });
    }
  }, [item?.images]);

  return {
    item,
    handleInviteUser,
    handleSaveUser,
    handleDownloadContent,
    handleSaveContent,
    handleNavigation,
    getLoading,
    handleBuyContent
  };
};

export default useAction;
