import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import InstagramIcon from '@mui/icons-material/Instagram';
import StarIcon from '@mui/icons-material/Star';
import { Box, Stack, Typography, styled } from '@mui/material';

import { ShowFnOutput, useModal } from 'mui-modal-provider';

import { venueGetCollabEstimateApi } from '../../api/invites/venueGetCollabEstimateApi';
import UserAvatarCategory from '../../components/Avatar/UserAvatarCategory/UserAvatarCategory';
import Button from '../../components/Button/Button';
import SaveUserButton from '../../components/Button/SaveUserButton';
import InviteDialog, {
  InviteDialogProps,
} from '../../components/Dialogs/CreateInvite/InviteDialog';
import { IGStatsDialog } from '../../components/Dialogs/IGStats/IGStatsDialog';
import { IGTitle } from '../../components/Dialogs/IGStats/components/IGTitle';
import FloatTopLeft from '../../components/FloatTopLeft/FloatTopLeft';
import GoBack from '../../components/GoBack/GoBack';
import TrustedIcon from '../../components/Icons/TrustedIcon/TrustedIcon';
import InstagramTag from '../../components/InstagramTag/InstagramTag';
import { Gallery } from '../../components/Photoswipe/Photoswipe';
import { PremiumTooltip } from '../../components/PremiumTooltip/PremiumTooltip';
import SkeletonWithChild from '../../components/SkeletonWithChild/SkeletonWithChild';
import TabPanelContainer, { Panel } from '../../components/TabPanelContainer/TabPanelContainer';
import { User } from '../../graphql/API';
import { useQueryUser } from '../../hooks/reactQuery/useQueryUser';
import useSubscriptionAccess from '../../hooks/useSubscriptionAccess';
import useVenueId from '../../hooks/useVenueId';
import { markHighlight } from '../../redux/features/invite/inviteSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import icons from '../../themes/icons';
import { formatNumber } from '../../utils/formatter';
import { UserCategory, getUserCategory } from '../../utils/user';
import { extractSubscriptionPlan } from '../SubscriptionPlans/common/helper';
import PanelUserContent from './components/PanelUserContent';
import PanelUserPortfolio from './components/PanelUserPortfolio';
import UserCategoryChip from './components/UserCategoryChip';

type ProfileMetricCardProps = {
  loading: boolean;
  label: string;
  value: number;
};

const ProfileMetricCard: React.FC<ProfileMetricCardProps> = ({ loading, label, value }) => (
  <SkeletonWithChild variant='rounded' loading={loading}>
    <Box minWidth='8rem'>
      <Typography variant='h5' fontWeight='bold'>
        {value}
      </Typography>
      <Typography sx={{ color: '#848484' }}>{label}</Typography>
    </Box>
  </SkeletonWithChild>
);

const ProfileStack = styled(Stack)(({ theme }) => ({
  'div:not(:first-of-type)': {
    borderLeft: '1px solid #F1F1F1',
    paddingLeft: theme.spacing(2),
  },
}));

const UserProfile: React.FC = () => {
  const subscriptionAccess = useSubscriptionAccess();
  const { subscription } = useAppSelector((state) => state.subscription.value);
  const inviteModalRef = React.useRef<ShowFnOutput<InviteDialogProps>>();
  const { showModal } = useModal();
  const dispatch = useAppDispatch();

  const { userId } = useParams<{ userId: string }>();

  const venueId = useVenueId();
  const { data, isLoading } = useQueryUser(venueId, userId);
  const userData = React.useMemo(() => data ?? ({} as User), [data]);

  const panels = React.useMemo<Panel[]>(
    () => [
      { label: 'Portfolio', children: <PanelUserPortfolio userId={data?.id} /> },
      { label: 'Content', children: <PanelUserContent userId={data?.id} /> },
    ],
    [data?.id],
  );

  const metrics = React.useMemo<Omit<ProfileMetricCardProps, 'loading'>[]>(
    () => [
      { label: 'Total Reservations', value: userData.totalReservations ?? 0 },
      { label: 'Checkins', value: userData.totalCheckins ?? 0 },
    ],
    [userData],
  );

  const handleCreateInviteDialog = React.useCallback(async () => {
    if (!data) {
      return;
    }

    const estimateResponse = await venueGetCollabEstimateApi({ userIDs: [Number(userData.id)] });
    inviteModalRef.current = showModal(InviteDialog, {
      title: 'Invite',
      users: [data],
      premiumCredits: estimateResponse?.totalPremiumCredits || 0,
      nonPremiumCredits: estimateResponse?.totalNonPremiumCredits || 0,
      modalRef: inviteModalRef,
      onSuccess: () => {
        dispatch(markHighlight(true));
      },
    });
  }, [data, dispatch, showModal, userData.id]);

  const handleIGStatsDialog = React.useCallback(() => {
    showModal(IGStatsDialog, {
      titleComponent: <IGTitle />,
      userID: userData.id,
    });
  }, [showModal, userData.id]);

  const fullProfile = React.useMemo(
    () => subscriptionAccess?.includes('COMPLETE_USER_PROFILES'),
    [subscriptionAccess],
  );

  const subscriptionPlan = React.useMemo(() => {
    const [plan] = extractSubscriptionPlan(subscription?.sku ?? '');

    return plan;
  }, [subscription]);

  const uc = React.useMemo(() => userData.tags && getUserCategory(userData.tags), [userData.tags]);

  return (
    <>
      <Helmet>
        <title>{`Beautypass - ${userData.firstname} ${userData.lastname}`}</title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          backgroundColor: 'white',
          flex: 1,
          py: 4,
          pl: 9,
          pr: 6,
        }}
      >
        <FloatTopLeft>
          <GoBack />
        </FloatTopLeft>
        <Box
          sx={{
            display: 'flex',
            backgroundColor: '#FAFAFA',
            borderRadius: 1,
            px: 4,
            pt: 2,
            pb: 4,
            mb: 4,
          }}
        >
          <SkeletonWithChild variant='circular' loading={isLoading}>
            <UserAvatarCategory
              variant='circular'
              alt={userData.firstname}
              placeholderSrc={String(userData.picture?.thumbnail)}
              src={String(userData.picture?.medium)}
              userCategory={userData.lifestyle ? UserCategory.lifestyle : UserCategory.select}
              sx={{
                width: '15rem',
                height: '15rem',
              }}
            />
          </SkeletonWithChild>
          <Box
            sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', ml: 5, py: 1 }}
          >
            <Box>
              <Typography
                variant='h5'
                component='div'
                fontWeight='bold'
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <SkeletonWithChild width='100%' loading={isLoading}>
                  {userData.firstname} {userData.lastname}
                  {userData.agencyProfileUrl && <TrustedIcon sx={{ fontSize: '1.2rem', ml: 1 }} />}
                  <UserCategoryChip
                    userCategory={userData.lifestyle ? UserCategory.lifestyle : UserCategory.select}
                    sx={{ ml: 1, px: 0.8, py: 1.8 }}
                  />
                </SkeletonWithChild>
              </Typography>

              <SkeletonWithChild width='80%' loading={isLoading}>
                <Typography variant='subtitle1' sx={{ display: 'flex', alignItems: 'center' }}>
                  {fullProfile && userData.instagramHandle ? (
                    <InstagramTag username={userData.instagramHandle} sx={{ mr: 1 }} />
                  ) : (
                    <InstagramIcon fontSize='small' sx={{ mr: 1 }} />
                  )}

                  {`${formatNumber(userData.instagramFollowers ?? 0)} Followers`}
                </Typography>
              </SkeletonWithChild>

              <SkeletonWithChild width='60%' loading={isLoading}>
                <Typography component='div' sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                  <StarIcon sx={{ fontSize: '1rem', mr: 1 }} />
                  <Box component='span' mr={0.5} fontWeight='bold'>
                    {userData?.rating?.toFixed(1)}
                  </Box>
                  Average rating ({userData.totalRatings} ratings)
                </Typography>
              </SkeletonWithChild>

              <ProfileStack spacing={4} direction='row' sx={{ mt: 4 }}>
                {metrics.map((x, i) => (
                  <ProfileMetricCard
                    key={`profile-metric-${i}`}
                    loading={isLoading}
                    label={x.label}
                    value={x.value}
                  />
                ))}
              </ProfileStack>
            </Box>

            <Stack spacing={4} direction='row' alignItems='flex-start'>
              <SaveUserButton
                size='large'
                userId={Number(userData.id)}
                saved={Boolean(userData.savedByVenue)}
              >
                Save
              </SaveUserButton>

              {!subscriptionPlan ? (
                <PremiumTooltip showStarterIcon>
                  <Button
                    color='secondary'
                    variant='contained'
                    size='large'
                    onClick={handleIGStatsDialog}
                    sx={{ px: 4, color: '#000000', backgroundColor: '#ececec' }}
                    startIcon={<icons.StatsIcon />}
                    disabled
                  >
                    Stats
                  </Button>
                </PremiumTooltip>
              ) : (
                <Button
                  color='secondary'
                  variant='contained'
                  size='large'
                  onClick={handleIGStatsDialog}
                  sx={{ px: 4, color: '#000000', backgroundColor: '#ececec' }}
                  startIcon={<icons.StatsIcon />}
                >
                  Stats
                </Button>
              )}

              {!data?.isEventReserved && (
                <Button
                  variant='contained'
                  size='large'
                  sx={{ px: 4 }}
                  onClick={handleCreateInviteDialog}
                >
                  Invite
                </Button>
              )}
            </Stack>
          </Box>
        </Box>

        <Gallery>
          <TabPanelContainer panels={panels} />
        </Gallery>
      </Box>
    </>
  );
};

export default UserProfile;
