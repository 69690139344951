import * as React from 'react';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SearchIcon from '@mui/icons-material/Search';
import { Box, InputAdornment, OutlinedInput, Stack } from '@mui/material';

import { debounce } from 'lodash';
import { MRT_SortingState, MaterialReactTable, useMaterialReactTable } from 'material-react-table';

import { venueListReservationsApi } from '../../../api/reservations/venueListReservationsApi';
import { useNotificationsContext } from '../../../contexts/NotificationsContext';
import { ReservationStatus, VenueListReservationsFilter } from '../../../graphql/API';
import useReservationQuery from '../../../hooks/reactQuery/useReservationQuery';
import useExportCsv from '../../../hooks/useExportCsv';
import useVenueId from '../../../hooks/useVenueId';
import LoadingButton from '../../Button/LoadingButton';
import ChipOptions from '../../ChipOptions/ChipOptions';
import useColumn from './Column';
import mapToCsv from './mapToCsv';
import './resrvation.css';

type ReservationsTableProps = {
  height?: string;
  detail?: boolean;
  filters?: Partial<VenueListReservationsFilter>;
};

const ReservationsTable: React.FC<ReservationsTableProps> = ({
  height,
  detail,
  filters: defaultFilters,
}) => {
  const venueID = useVenueId();

  const [exportLoading, setExportLoading] = React.useState<boolean>();
  const [filters, setFilters] = React.useState<Partial<VenueListReservationsFilter> | undefined>(
    defaultFilters,
  );
  const columns = useColumn({ detail });
  const [sorting, setSorting] = React.useState<MRT_SortingState>([
    { id: defaultFilters?.sortBy ?? 'createdAt', desc: defaultFilters?.sortDirection === 'desc' },
  ]);

  const { data, fetchNextPage, hasNextPage, isFetching, isLoading } = useReservationQuery(
    filters,
    venueID,
  );

  const { handleUpdateReservation } =
    useNotificationsContext() || {};

  const { doExportData } = useExportCsv();

  const fetchMoreOnBottomReached = React.useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
        if (scrollHeight - scrollTop - clientHeight < 500 && !isFetching && hasNextPage) {
          fetchNextPage();
        }
      }
    },
    [isFetching, fetchNextPage, hasNextPage],
  );

  React.useEffect(() => {
    if (data.length > 0 && handleUpdateReservation) {
      handleUpdateReservation(data[0].createdAt || '');
    }
  }, [data]);

  React.useEffect(() => {
    const [sort] = sorting;
    setFilters((x) => ({ ...x, sortBy: sort?.id, sortDirection: sort?.desc ? 'desc' : 'asc' }));
  }, [sorting]);

  const handleExportData = React.useCallback(async () => {
    setExportLoading(true);
    const items = await venueListReservationsApi({
      ...filters,
      limit: 9999,
      offset: 0,
      venueID,
    });

    doExportData(items.map(mapToCsv));
    setExportLoading(false);
  }, [doExportData, venueID, filters]);

  const table = useMaterialReactTable({
    enableStickyHeader: true,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: false,
    enableSorting: true,
    manualSorting: true,
    onSortingChange: setSorting,
    muiTableHeadProps: { sx: { opacity: 1 } },
    muiTableHeadRowProps: {
      sx: { backgroundColor: 'white', boxShadow: 'none' },
    },
    muiTableBodyRowProps: {
      sx: { backgroundColor: 'inherit' },
    },
    muiTableBodyCellProps: {
      sx: ({ custom }) => ({ borderColor: custom.colors.brandLightGrey }),
    },
    muiTableHeadCellProps: {
      sx: ({ custom }) => ({
        color: 'text.secondary',
        borderColor: custom.colors.brandLightGrey,
      }),
    },
    muiTablePaperProps: { sx: { boxShadow: 'none' } },
    muiTableContainerProps: {
      sx: { height, maxHeight: height, backgroundColor: 'white' },
      onScroll: (event) => fetchMoreOnBottomReached(event.target as HTMLDivElement),
    },
    columns,
    data,
    rowVirtualizerOptions: { overscan: 5 },
    state: { sorting, isLoading: isLoading || isFetching },
  });

  const handleSearch = debounce((value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      searchText: value,
    }));
  }, 500);

  const handleChange = React.useCallback(
    (value?: string) => {
      handleSearch(value);
    },
    [handleSearch],
  );

  return (
    <Stack spacing={4}>
      <Box display='flex' justifyContent='space-between'>
        <ChipOptions
          name='reservationStatus'
          options={[
            { label: 'All', value: '' },
            { label: 'Reserved', value: ReservationStatus.reserved },
            { label: 'Checked in', value: ReservationStatus.checkedin },
            { label: 'Cancelled', value: ReservationStatus.cancelled },
            { label: 'No show', value: ReservationStatus.noshow },
          ]}
          onChange={({ value: v }) =>
            setFilters((pre) => ({ ...pre, status: v ? [v as ReservationStatus] : undefined }))
          }
        />

        <Stack direction='row' spacing={1}>
          <OutlinedInput
            id='outlined-adornment-amount'
            placeholder='Search a member...'
            startAdornment={
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            }
            onChange={(e) => {
              handleChange(e.target.value);
            }}
            size='small'
          />
          <LoadingButton
            variant='outlined'
            onClick={handleExportData}
            startIcon={<FileDownloadIcon />}
            loading={exportLoading}
          >
            Export Data
          </LoadingButton>
        </Stack>
      </Box>
      <MaterialReactTable table={table} />
    </Stack>
  );
};

export default ReservationsTable;
