import * as React from 'react';
import { Link } from 'react-router-dom';

import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined';
import { Skeleton } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip, { ChipProps } from '@mui/material/Chip';
import MuiLink from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';

import { OfferCategory } from '../../../graphql/API';
import { useTermsAndConditions } from '../../../hooks/useTermsAndConditions';
import { extractSubscriptionPlan } from '../../../pages/SubscriptionPlans/common/helper';
import { Plan } from '../../../pages/SubscriptionPlans/types';
import { useAppReset, useAppSelector } from '../../../redux/hooks';
import Button from '../../Button/Button';
import { UpdateToOurTermsDialog } from '../../Dialogs/UpdateToOurTerms/UpdateToOurTermsDialog';
import SvgIcon from '../../Icons';
import { Balance } from '../Balance';
import Menu from './Menu';

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: 'white',
  color: 'black',
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  boxShadow: theme.shadows[1],
}));

export const mapPlanLabel: Partial<Record<Plan, { label: string; color: string; fill: string }>> = {
  plus: { label: 'PLUS', color: '#D86EEF', fill: 'url(#linear-gradient)' },
  starter: { label: 'Starter', color: '#429C12', fill: '#429C12' },
};

export const SubscriptionPlan: React.FC<ChipProps & { planColor: string; fill: string }> = ({
  label,
  planColor,
  fill,
}) => (
  <Chip
    icon={<SvgIcon name='Plus' sx={{ fill }} />}
    label={label}
    variant='outlined'
    color='primary'
    sx={{
      color: planColor,
      mr: 2,
      borderWidth: '1.5px',
      fontWeight: 'medium',
      borderColor: planColor,
      '.MuiChip-icon': {
        ml: 1,
      },
    }}
  />
);

const settings = [
  { label: 'Profile', path: 'profile' },
  { label: 'Balance', path: 'balance' },
  { label: 'Premium Plans', path: 'plans' },
];

const Header: React.FC = () => {
  const termsData = useTermsAndConditions();
  const { subscription } = useAppSelector((state) => state.subscription.value);
  const { value, isLoading } = useAppSelector((state) => state.balance);

  const { balance, status } = value || {};

  const { signOut } = useAuthenticator();
  const venueState = useAppSelector((state) => state.venue.value);
  const resetApp = useAppReset();
  const [showTermsAndConditionDialog, setShowTermsAndConditionDialog] = React.useState(false);

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const tncVersion = venueState?.tncVersion;

  const tncUpdateRequired = Boolean(
    termsData?.updateDate &&
      (!venueState?.termsAccepted || !tncVersion || termsData?.updateDate !== tncVersion),
  );

  React.useEffect(() => {
    setShowTermsAndConditionDialog(tncUpdateRequired);
  }, [tncUpdateRequired]);

  const handleOpenUserMenu = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  }, []);

  const handleCloseUserMenu = React.useCallback(() => {
    setAnchorElUser(null);
  }, []);

  const onSignout = React.useCallback(async () => {
    await Auth.signOut();
    signOut();
    handleCloseUserMenu();

    setTimeout(() => {
      resetApp();
    }, 500);
  }, [signOut, handleCloseUserMenu, resetApp]);

  const subscriptionPlan = React.useMemo(() => {
    const [plan] = extractSubscriptionPlan(subscription?.sku ?? '');

    return plan;
  }, [subscription]);

  return (
    <AppBar position='relative' sx={{ boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 6px 0px' }}>
      <Toolbar disableGutters sx={{ px: 3 }}>
        <Typography
          component='div'
          variant='body2'
          fontWeight='medium'
          sx={{ display: 'flex', alignItems: 'center', color: '#B4B4B4' }}
        >
          <Box component={Link} sx={{ mr: 1.5, display: 'flex' }}>
            <img height={30} src='/logo-header.png' />
          </Box>
          Beta
        </Typography>

        <Box sx={{ flexGrow: 1 }}></Box>

        <Box display='flex' alignItems='center' sx={{ flexGrow: 0 }}>
          {subscriptionPlan === 'plus' ||
          (venueState?.type === OfferCategory.brand && subscriptionPlan === 'starter') ? null : (
            <Button
              component={Link}
              to='/plans'
              id='btn-premium-plans'
              startIcon={<SvgIcon name='Plus' sx={{ fill: 'url(#linear-gradient)' }} />}
              variant='outlined'
              sx={{
                marginX: '8px',
                borderColor: '#D86EEF',
                ':hover': {
                  borderColor: '#D86EEF',
                },
              }}
            >
              Upgrade your Plan
            </Button>
          )}

          {isLoading ? (
            <Skeleton width={'84px'} height={'60px'} sx={{ marginX: '4px' }} />
          ) : (
            <Box
              component={Link}
              to={'/balance'}
              sx={{
                marginX: '4px',
                textDecoration: 'none',
                pointerEvents:
                  venueState?.type === OfferCategory.brand && !subscriptionPlan ? 'none' : '',
              }}
            >
              <Balance
                currency={venueState?.currency || ''}
                balance={balance || 0}
                status={status}
              />
            </Box>
          )}

          <Tooltip title='Open settings'>
            <Chip
              onClick={handleOpenUserMenu}
              avatar={<Avatar alt='avatar' src={venueState?.picture?.thumbnail || ''} />}
              label={
                <Box display='flex'>
                  <NotesOutlinedIcon />
                </Box>
              }
              variant='outlined'
            />
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id='menu-appbar'
            anchorEl={anchorElUser}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((x) => (
              <MenuItem divider key={x.path} onClick={handleCloseUserMenu}>
                <MuiLink
                  component={Link}
                  to={x.path}
                  width='100%'
                  underline='none'
                  fontWeight='bold'
                  sx={{ paddingY: '6px' }}
                >
                  {x.label}
                </MuiLink>
              </MenuItem>
            ))}
            <MenuItem onClick={onSignout} sx={{ marginTop: '32px' }}>
              <Typography textAlign='center'>Logout</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
      <UpdateToOurTermsDialog
        open={showTermsAndConditionDialog}
        disableEscapeKeyDown
        onClose={() => {
          return;
        }}
        onSuccess={() => {
          setShowTermsAndConditionDialog(false);
        }}
        termsData={termsData}
      />
    </AppBar>
  );
};
export default Header;
