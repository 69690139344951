import * as React from 'react';

import MuiSvgIcon, { SvgIconProps as MuiSvgIconProps } from '@mui/material/SvgIcon';

import { ReactComponent as Chat } from '../../assets/icons/chat.svg';
import { ReactComponent as Calendar } from '../../components/Icons/svg/calendar.svg';
import { ReactComponent as Collabs } from '../../components/Icons/svg/collabs-cropped.svg';
import { ReactComponent as Content } from '../../components/Icons/svg/content.svg';
import { ReactComponent as DressCode } from '../../components/Icons/svg/dresscode.svg';
import { ReactComponent as FeedBlack } from '../../components/Icons/svg/feed-black.svg';
import { ReactComponent as Feed } from '../../components/Icons/svg/feed.svg';
import { ReactComponent as Filter } from '../../components/Icons/svg/filters.svg';
import { ReactComponent as Hamburger } from '../../components/Icons/svg/hamburger-menu.svg';
import { ReactComponent as Home } from '../../components/Icons/svg/home-cropped.svg';
import { ReactComponent as InviteSent } from '../../components/Icons/svg/invite-sent-cropped.svg';
import { ReactComponent as Key } from '../../components/Icons/svg/key.svg';
import { ReactComponent as NewOfferClear } from '../../components/Icons/svg/new-offer-clear.svg';
import { ReactComponent as NewOffer } from '../../components/Icons/svg/new-offer.svg';
import { ReactComponent as NoRequirement } from '../../components/Icons/svg/no-requirements.svg';
import { ReactComponent as Offers } from '../../components/Icons/svg/offers-cropped.svg';
import { ReactComponent as Plus } from '../../components/Icons/svg/plus.svg';
import { ReactComponent as Product } from '../../components/Icons/svg/product.svg';
import { ReactComponent as ReelBlack } from '../../components/Icons/svg/reel-black.svg';
import { ReactComponent as Reel } from '../../components/Icons/svg/reel.svg';
import { ReactComponent as Reservation } from '../../components/Icons/svg/reservation-cropped.svg';
import { ReactComponent as Save } from '../../components/Icons/svg/save.svg';
import { ReactComponent as Saved } from '../../components/Icons/svg/saved.svg';
import { ReactComponent as StoryBlack } from '../../components/Icons/svg/story-black.svg';
import { ReactComponent as Story } from '../../components/Icons/svg/story.svg';
import { ReactComponent as Tip } from '../../components/Icons/svg/tip.svg';
import { ReactComponent as User } from '../../components/Icons/svg/user.svg';
import { ReactComponent as Wallet } from '../../components/Icons/svg/wallet.svg';

const mapIcon = {
  Collabs,
  Content,
  DressCode,
  FeedBlack,
  Feed,
  Hamburger,
  NewOffer,
  NewOfferClear,
  NoRequirement,
  Offers,
  Product,
  ReelBlack,
  Reel,
  Reservation,
  StoryBlack,
  Story,
  Tip,
  User,
  Home,
  Calendar,
  Saved,
  Save,
  InviteSent,
  Filter,
  Wallet,
  Plus,
  Key,
  Chat,
};

export type IconName = keyof typeof mapIcon;
type SvgIconProps = {
  name: IconName;
} & MuiSvgIconProps;

type SvgIconComponent = React.FC<MuiSvgIconProps>;

const SvgIcon: React.FC<SvgIconProps> = ({ name, ...restProps }) => {
  return (
    <MuiSvgIcon component={mapIcon[name]} inheritViewBox sx={{ fontSize: '24px' }} {...restProps} />
  );
};

export default SvgIcon;

export const ReservationIcon: SvgIconComponent = (props) => (
  <SvgIcon {...props} name='Reservation' />
);
export const CalendarIcon: SvgIconComponent = (props) => <SvgIcon {...props} name='Calendar' />;
export const HomeIcon: SvgIconComponent = (props) => <SvgIcon {...props} name='Home' />;
export const CollabsIcon: SvgIconComponent = (props) => <SvgIcon {...props} name='Collabs' />;
export const OffersIcon: SvgIconComponent = (props) => <SvgIcon {...props} name='Offers' />;
export const StoryIcon: SvgIconComponent = (props) => <SvgIcon {...props} name='Story' />;
export const UserIcon: SvgIconComponent = (props) => <SvgIcon {...props} name='User' />;
export const KeyIcon: SvgIconComponent = (props) => <SvgIcon {...props} name='Key' />;
export const NewOfferIcon: SvgIconComponent = (props) => (
  <SvgIcon {...props} name='NewOfferClear' />
);
export const ChatIcon: SvgIconComponent = (props) => <SvgIcon {...props} name='Chat' />;

export const NoRequirementIcon: SvgIconComponent = (props) => (
  <SvgIcon {...props} name='NoRequirement' />
);
