/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const agencyCreateUserInvite = /* GraphQL */ `mutation AgencyCreateUserInvite($input: AgencyCreateRequest) {
  agencyCreateUserInvite(input: $input)
}
` as GeneratedMutation<
  APITypes.AgencyCreateUserInviteMutationVariables,
  APITypes.AgencyCreateUserInviteMutation
>;
export const userActionAgencyInvite = /* GraphQL */ `mutation UserActionAgencyInvite($input: UserActionAgencyRequest) {
  userActionAgencyInvite(input: $input)
}
` as GeneratedMutation<
  APITypes.UserActionAgencyInviteMutationVariables,
  APITypes.UserActionAgencyInviteMutation
>;
export const agencyMarkInviteSeen = /* GraphQL */ `mutation AgencyMarkInviteSeen($input: AgencyMarkInviteSeenRequest) {
  agencyMarkInviteSeen(input: $input)
}
` as GeneratedMutation<
  APITypes.AgencyMarkInviteSeenMutationVariables,
  APITypes.AgencyMarkInviteSeenMutation
>;
export const agencyInviteAgreementSign = /* GraphQL */ `mutation AgencyInviteAgreementSign($input: AgencyInviteAgreementSignRequest) {
  agencyInviteAgreementSign(input: $input)
}
` as GeneratedMutation<
  APITypes.AgencyInviteAgreementSignMutationVariables,
  APITypes.AgencyInviteAgreementSignMutation
>;
export const serviceResetPassword = /* GraphQL */ `mutation ServiceResetPassword($input: ResetPasswordInput) {
  serviceResetPassword(input: $input)
}
` as GeneratedMutation<
  APITypes.ServiceResetPasswordMutationVariables,
  APITypes.ServiceResetPasswordMutation
>;
export const adminApproveVenueLeads = /* GraphQL */ `mutation AdminApproveVenueLeads($id: String!) {
  adminApproveVenueLeads(id: $id)
}
` as GeneratedMutation<
  APITypes.AdminApproveVenueLeadsMutationVariables,
  APITypes.AdminApproveVenueLeadsMutation
>;
export const adminRejectVenueLeads = /* GraphQL */ `mutation AdminRejectVenueLeads($id: String!) {
  adminRejectVenueLeads(id: $id)
}
` as GeneratedMutation<
  APITypes.AdminRejectVenueLeadsMutationVariables,
  APITypes.AdminRejectVenueLeadsMutation
>;
export const adminCreateMarket = /* GraphQL */ `mutation AdminCreateMarket($input: AdminCreateMarketInput!) {
  adminCreateMarket(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminCreateMarketMutationVariables,
  APITypes.AdminCreateMarketMutation
>;
export const adminUpdateMarket = /* GraphQL */ `mutation AdminUpdateMarket($input: AdminUpdateMarketInput!) {
  adminUpdateMarket(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminUpdateMarketMutationVariables,
  APITypes.AdminUpdateMarketMutation
>;
export const adminCreateVenue = /* GraphQL */ `mutation AdminCreateVenue($input: AdminCreateVenueInput!) {
  adminCreateVenue(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminCreateVenueMutationVariables,
  APITypes.AdminCreateVenueMutation
>;
export const adminUpdateVenue = /* GraphQL */ `mutation AdminUpdateVenue($input: AdminUpdateVenueInput!) {
  adminUpdateVenue(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminUpdateVenueMutationVariables,
  APITypes.AdminUpdateVenueMutation
>;
export const adminUpdateVenues = /* GraphQL */ `mutation AdminUpdateVenues($input: AdminUpdateVenuesInput!) {
  adminUpdateVenues(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminUpdateVenuesMutationVariables,
  APITypes.AdminUpdateVenuesMutation
>;
export const venueAdminUpdateVenue = /* GraphQL */ `mutation VenueAdminUpdateVenue($input: AdminUpdateVenueInput!) {
  venueAdminUpdateVenue(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueAdminUpdateVenueMutationVariables,
  APITypes.VenueAdminUpdateVenueMutation
>;
export const venueConnectVenue = /* GraphQL */ `mutation VenueConnectVenue($input: VenueConnectVenueInput!) {
  venueConnectVenue(input: $input) {
    connectedVenueID
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.VenueConnectVenueMutationVariables,
  APITypes.VenueConnectVenueMutation
>;
export const adminDeleteVenue = /* GraphQL */ `mutation AdminDeleteVenue($id: String!) {
  adminDeleteVenue(id: $id)
}
` as GeneratedMutation<
  APITypes.AdminDeleteVenueMutationVariables,
  APITypes.AdminDeleteVenueMutation
>;
export const adminUpdateStaffApi = /* GraphQL */ `mutation AdminUpdateStaffApi($input: EditStaffValuesInput!) {
  adminUpdateStaffApi(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminUpdateStaffApiMutationVariables,
  APITypes.AdminUpdateStaffApiMutation
>;
export const adminCreateStaffApi = /* GraphQL */ `mutation AdminCreateStaffApi($input: EditStaffValuesInput!) {
  adminCreateStaffApi(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminCreateStaffApiMutationVariables,
  APITypes.AdminCreateStaffApiMutation
>;
export const adminCreateOffer = /* GraphQL */ `mutation AdminCreateOffer($input: AdminCreateOfferInput!) {
  adminCreateOffer(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminCreateOfferMutationVariables,
  APITypes.AdminCreateOfferMutation
>;
export const adminCreateOfferV2 = /* GraphQL */ `mutation AdminCreateOfferV2($input: AdminCreateOfferInput!) {
  adminCreateOfferV2(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminCreateOfferV2MutationVariables,
  APITypes.AdminCreateOfferV2Mutation
>;
export const venueAdminCreateOffer = /* GraphQL */ `mutation VenueAdminCreateOffer($input: AdminCreateOfferInput!) {
  venueAdminCreateOffer(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueAdminCreateOfferMutationVariables,
  APITypes.VenueAdminCreateOfferMutation
>;
export const adminUpdateOffer = /* GraphQL */ `mutation AdminUpdateOffer($input: AdminUpdateOfferInput!) {
  adminUpdateOffer(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminUpdateOfferMutationVariables,
  APITypes.AdminUpdateOfferMutation
>;
export const venueAdminUpdateOffer = /* GraphQL */ `mutation VenueAdminUpdateOffer($input: AdminUpdateOfferInput!) {
  venueAdminUpdateOffer(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueAdminUpdateOfferMutationVariables,
  APITypes.VenueAdminUpdateOfferMutation
>;
export const adminDeleteOffer = /* GraphQL */ `mutation AdminDeleteOffer($id: String!) {
  adminDeleteOffer(id: $id)
}
` as GeneratedMutation<
  APITypes.AdminDeleteOfferMutationVariables,
  APITypes.AdminDeleteOfferMutation
>;
export const adminCreateUser = /* GraphQL */ `mutation AdminCreateUser($input: AdminCreateUserInput!) {
  adminCreateUser(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminCreateUserMutationVariables,
  APITypes.AdminCreateUserMutation
>;
export const adminUpdateUser = /* GraphQL */ `mutation AdminUpdateUser($input: AdminUpdateUserInput!) {
  adminUpdateUser(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminUpdateUserMutationVariables,
  APITypes.AdminUpdateUserMutation
>;
export const adminUpdateUsers = /* GraphQL */ `mutation AdminUpdateUsers($input: AdminUpdateUsersInput!) {
  adminUpdateUsers(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminUpdateUsersMutationVariables,
  APITypes.AdminUpdateUsersMutation
>;
export const adminUpdateUserInstagram = /* GraphQL */ `mutation AdminUpdateUserInstagram($input: AdminUpdateUserInstagramInput!) {
  adminUpdateUserInstagram(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminUpdateUserInstagramMutationVariables,
  APITypes.AdminUpdateUserInstagramMutation
>;
export const adminInstagramScrape = /* GraphQL */ `mutation AdminInstagramScrape($input: AdminInstagramScrapeInput!) {
  adminInstagramScrape(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminInstagramScrapeMutationVariables,
  APITypes.AdminInstagramScrapeMutation
>;
export const adminEnableUser = /* GraphQL */ `mutation AdminEnableUser($userID: String!) {
  adminEnableUser(userID: $userID)
}
` as GeneratedMutation<
  APITypes.AdminEnableUserMutationVariables,
  APITypes.AdminEnableUserMutation
>;
export const adminUnblockUser = /* GraphQL */ `mutation AdminUnblockUser($userID: String!) {
  adminUnblockUser(userID: $userID)
}
` as GeneratedMutation<
  APITypes.AdminUnblockUserMutationVariables,
  APITypes.AdminUnblockUserMutation
>;
export const adminUpdatePassword = /* GraphQL */ `mutation AdminUpdatePassword($input: AdminUpdatePasswordInput!) {
  adminUpdatePassword(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminUpdatePasswordMutationVariables,
  APITypes.AdminUpdatePasswordMutation
>;
export const adminDeleteUser = /* GraphQL */ `mutation AdminDeleteUser($id: String!) {
  adminDeleteUser(id: $id)
}
` as GeneratedMutation<
  APITypes.AdminDeleteUserMutationVariables,
  APITypes.AdminDeleteUserMutation
>;
export const adminMergeUsers = /* GraphQL */ `mutation AdminMergeUsers($input: AdminMergeUsersInput!) {
  adminMergeUsers(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminMergeUsersMutationVariables,
  APITypes.AdminMergeUsersMutation
>;
export const adminUpdateReservation = /* GraphQL */ `mutation AdminUpdateReservation($input: AdminUpdateReservationInput!) {
  adminUpdateReservation(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminUpdateReservationMutationVariables,
  APITypes.AdminUpdateReservationMutation
>;
export const supportReviewReservation = /* GraphQL */ `mutation SupportReviewReservation($input: SupportReviewReservationInput!) {
  supportReviewReservation(input: $input)
}
` as GeneratedMutation<
  APITypes.SupportReviewReservationMutationVariables,
  APITypes.SupportReviewReservationMutation
>;
export const venueReviewReservation = /* GraphQL */ `mutation VenueReviewReservation($input: SupportReviewReservationInput!) {
  venueReviewReservation(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueReviewReservationMutationVariables,
  APITypes.VenueReviewReservationMutation
>;
export const adminDeleteReservation = /* GraphQL */ `mutation AdminDeleteReservation($id: String!) {
  adminDeleteReservation(id: $id)
}
` as GeneratedMutation<
  APITypes.AdminDeleteReservationMutationVariables,
  APITypes.AdminDeleteReservationMutation
>;
export const adminDeleteReservationContent = /* GraphQL */ `mutation AdminDeleteReservationContent($id: String!) {
  adminDeleteReservationContent(id: $id)
}
` as GeneratedMutation<
  APITypes.AdminDeleteReservationContentMutationVariables,
  APITypes.AdminDeleteReservationContentMutation
>;
export const adminUpdateInvitePaymentToRelease = /* GraphQL */ `mutation AdminUpdateInvitePaymentToRelease($input: AdminUpdatePaymentInput!) {
  adminUpdateInvitePaymentToRelease(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminUpdateInvitePaymentToReleaseMutationVariables,
  APITypes.AdminUpdateInvitePaymentToReleaseMutation
>;
export const adminUpdateInvitePaymentToStop = /* GraphQL */ `mutation AdminUpdateInvitePaymentToStop($input: AdminUpdatePaymentInput!) {
  adminUpdateInvitePaymentToStop(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminUpdateInvitePaymentToStopMutationVariables,
  APITypes.AdminUpdateInvitePaymentToStopMutation
>;
export const venueUpdateInvitePaymentToRelease = /* GraphQL */ `mutation VenueUpdateInvitePaymentToRelease($input: VenueUpdatePaymentInput!) {
  venueUpdateInvitePaymentToRelease(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueUpdateInvitePaymentToReleaseMutationVariables,
  APITypes.VenueUpdateInvitePaymentToReleaseMutation
>;
export const venueUpdateInvitePaymentToStop = /* GraphQL */ `mutation VenueUpdateInvitePaymentToStop($input: VenueUpdatePaymentInput!) {
  venueUpdateInvitePaymentToStop(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueUpdateInvitePaymentToStopMutationVariables,
  APITypes.VenueUpdateInvitePaymentToStopMutation
>;
export const adminCreatePenalty = /* GraphQL */ `mutation AdminCreatePenalty($input: AdminCreatePenaltyInput!) {
  adminCreatePenalty(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminCreatePenaltyMutationVariables,
  APITypes.AdminCreatePenaltyMutation
>;
export const adminUpdatePenalty = /* GraphQL */ `mutation AdminUpdatePenalty($input: AdminUpdatePenaltyInput!) {
  adminUpdatePenalty(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminUpdatePenaltyMutationVariables,
  APITypes.AdminUpdatePenaltyMutation
>;
export const adminDeletePenalty = /* GraphQL */ `mutation AdminDeletePenalty($id: String!) {
  adminDeletePenalty(id: $id)
}
` as GeneratedMutation<
  APITypes.AdminDeletePenaltyMutationVariables,
  APITypes.AdminDeletePenaltyMutation
>;
export const adminCreateSuspension = /* GraphQL */ `mutation AdminCreateSuspension($input: AdminCreateSuspensionInput!) {
  adminCreateSuspension(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminCreateSuspensionMutationVariables,
  APITypes.AdminCreateSuspensionMutation
>;
export const adminUpdateSuspension = /* GraphQL */ `mutation AdminUpdateSuspension($input: AdminUpdateSuspensionInput!) {
  adminUpdateSuspension(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminUpdateSuspensionMutationVariables,
  APITypes.AdminUpdateSuspensionMutation
>;
export const adminDeleteSuspension = /* GraphQL */ `mutation AdminDeleteSuspension($id: String!) {
  adminDeleteSuspension(id: $id)
}
` as GeneratedMutation<
  APITypes.AdminDeleteSuspensionMutationVariables,
  APITypes.AdminDeleteSuspensionMutation
>;
export const adminCreateCampaign = /* GraphQL */ `mutation AdminCreateCampaign($input: AdminCreateCampaignInput!) {
  adminCreateCampaign(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminCreateCampaignMutationVariables,
  APITypes.AdminCreateCampaignMutation
>;
export const adminUpdateCampaign = /* GraphQL */ `mutation AdminUpdateCampaign($input: AdminUpdateCampaignInput!) {
  adminUpdateCampaign(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminUpdateCampaignMutationVariables,
  APITypes.AdminUpdateCampaignMutation
>;
export const adminDeleteCampaign = /* GraphQL */ `mutation AdminDeleteCampaign($id: String!) {
  adminDeleteCampaign(id: $id)
}
` as GeneratedMutation<
  APITypes.AdminDeleteCampaignMutationVariables,
  APITypes.AdminDeleteCampaignMutation
>;
export const adminMarkUserComplaint = /* GraphQL */ `mutation AdminMarkUserComplaint($input: AdminMarkUserComplaintInput!) {
  adminMarkUserComplaint(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminMarkUserComplaintMutationVariables,
  APITypes.AdminMarkUserComplaintMutation
>;
export const adminMarkVenueComplaint = /* GraphQL */ `mutation AdminMarkVenueComplaint($input: AdminMarkUserComplaintInput!) {
  adminMarkVenueComplaint(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminMarkVenueComplaintMutationVariables,
  APITypes.AdminMarkVenueComplaintMutation
>;
export const userChangeMutedVenue = /* GraphQL */ `mutation UserChangeMutedVenue($input: UserUpdateMutedVenueInput!) {
  userChangeMutedVenue(input: $input)
}
` as GeneratedMutation<
  APITypes.UserChangeMutedVenueMutationVariables,
  APITypes.UserChangeMutedVenueMutation
>;
export const userChangeVenueFavourite = /* GraphQL */ `mutation UserChangeVenueFavourite($input: UserUpdateVenueFavouriteInput!) {
  userChangeVenueFavourite(input: $input)
}
` as GeneratedMutation<
  APITypes.UserChangeVenueFavouriteMutationVariables,
  APITypes.UserChangeVenueFavouriteMutation
>;
export const userRateVenue = /* GraphQL */ `mutation UserRateVenue($input: UserVenueRateInput!) {
  userRateVenue(input: $input)
}
` as GeneratedMutation<
  APITypes.UserRateVenueMutationVariables,
  APITypes.UserRateVenueMutation
>;
export const userCreateUser = /* GraphQL */ `mutation UserCreateUser($input: UserCreateUserInput!) {
  userCreateUser(input: $input)
}
` as GeneratedMutation<
  APITypes.UserCreateUserMutationVariables,
  APITypes.UserCreateUserMutation
>;
export const userUpdateUser = /* GraphQL */ `mutation UserUpdateUser($input: UserUpdateUserInput!) {
  userUpdateUser(input: $input)
}
` as GeneratedMutation<
  APITypes.UserUpdateUserMutationVariables,
  APITypes.UserUpdateUserMutation
>;
export const userCreatePortfolioContent = /* GraphQL */ `mutation UserCreatePortfolioContent($input: UserCreatePortfolioInput!) {
  userCreatePortfolioContent(input: $input)
}
` as GeneratedMutation<
  APITypes.UserCreatePortfolioContentMutationVariables,
  APITypes.UserCreatePortfolioContentMutation
>;
export const userDeletePortfolioContent = /* GraphQL */ `mutation UserDeletePortfolioContent($input: UserDeletePortfolioInput!) {
  userDeletePortfolioContent(input: $input)
}
` as GeneratedMutation<
  APITypes.UserDeletePortfolioContentMutationVariables,
  APITypes.UserDeletePortfolioContentMutation
>;
export const userUpdateEmail = /* GraphQL */ `mutation UserUpdateEmail($input: UserUpdateEmailInput!) {
  userUpdateEmail(input: $input)
}
` as GeneratedMutation<
  APITypes.UserUpdateEmailMutationVariables,
  APITypes.UserUpdateEmailMutation
>;
export const userUpdatePassword = /* GraphQL */ `mutation UserUpdatePassword($input: UserUpdatePasswordInput!) {
  userUpdatePassword(input: $input)
}
` as GeneratedMutation<
  APITypes.UserUpdatePasswordMutationVariables,
  APITypes.UserUpdatePasswordMutation
>;
export const userCreateReservation = /* GraphQL */ `mutation UserCreateReservation($input: UserCreateReservationInput!) {
  userCreateReservation(input: $input)
}
` as GeneratedMutation<
  APITypes.UserCreateReservationMutationVariables,
  APITypes.UserCreateReservationMutation
>;
export const userActionInvite = /* GraphQL */ `mutation UserActionInvite($input: UserActionInviteInput!) {
  userActionInvite(input: $input)
}
` as GeneratedMutation<
  APITypes.UserActionInviteMutationVariables,
  APITypes.UserActionInviteMutation
>;
export const userMarkInviteSeen = /* GraphQL */ `mutation UserMarkInviteSeen($input: UserMarkInviteInput!) {
  userMarkInviteSeen(input: $input)
}
` as GeneratedMutation<
  APITypes.UserMarkInviteSeenMutationVariables,
  APITypes.UserMarkInviteSeenMutation
>;
export const userCreateReservationContent = /* GraphQL */ `mutation UserCreateReservationContent(
  $input: UserCreateReservationContentInput!
) {
  userCreateReservationContent(input: $input)
}
` as GeneratedMutation<
  APITypes.UserCreateReservationContentMutationVariables,
  APITypes.UserCreateReservationContentMutation
>;
export const userUpdateReservation = /* GraphQL */ `mutation UserUpdateReservation($input: UserUpdateReservationInput!) {
  userUpdateReservation(input: $input)
}
` as GeneratedMutation<
  APITypes.UserUpdateReservationMutationVariables,
  APITypes.UserUpdateReservationMutation
>;
export const userUpdateReservationContent = /* GraphQL */ `mutation UserUpdateReservationContent(
  $input: UserUpdateReservationContentInput!
) {
  userUpdateReservationContent(input: $input)
}
` as GeneratedMutation<
  APITypes.UserUpdateReservationContentMutationVariables,
  APITypes.UserUpdateReservationContentMutation
>;
export const userCreateAccessRequest = /* GraphQL */ `mutation UserCreateAccessRequest($input: UserCreateAccessRequestInput!) {
  userCreateAccessRequest(input: $input)
}
` as GeneratedMutation<
  APITypes.UserCreateAccessRequestMutationVariables,
  APITypes.UserCreateAccessRequestMutation
>;
export const userCreateAccessRequestV2 = /* GraphQL */ `mutation UserCreateAccessRequestV2($input: UserCreateAccessRequestInput!) {
  userCreateAccessRequestV2(input: $input)
}
` as GeneratedMutation<
  APITypes.UserCreateAccessRequestV2MutationVariables,
  APITypes.UserCreateAccessRequestV2Mutation
>;
export const userCancelAccessRequest = /* GraphQL */ `mutation UserCancelAccessRequest($input: UserCancelAccessRequestInput!) {
  userCancelAccessRequest(input: $input)
}
` as GeneratedMutation<
  APITypes.UserCancelAccessRequestMutationVariables,
  APITypes.UserCancelAccessRequestMutation
>;
export const venueActionAccessRequest = /* GraphQL */ `mutation VenueActionAccessRequest($input: VenueActionAccessRequest!) {
  venueActionAccessRequest(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueActionAccessRequestMutationVariables,
  APITypes.VenueActionAccessRequestMutation
>;
export const userCreateComplaint = /* GraphQL */ `mutation UserCreateComplaint($input: UserCreateComplaintInput!) {
  userCreateComplaint(input: $input)
}
` as GeneratedMutation<
  APITypes.UserCreateComplaintMutationVariables,
  APITypes.UserCreateComplaintMutation
>;
export const venueCreateComplaint = /* GraphQL */ `mutation VenueCreateComplaint($input: VenueCreateComplaintInput!) {
  venueCreateComplaint(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueCreateComplaintMutationVariables,
  APITypes.VenueCreateComplaintMutation
>;
export const venueCreatePenalty = /* GraphQL */ `mutation VenueCreatePenalty($input: VenueCreatePenaltyInput!) {
  venueCreatePenalty(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueCreatePenaltyMutationVariables,
  APITypes.VenueCreatePenaltyMutation
>;
export const userUpdateNotifications = /* GraphQL */ `mutation UserUpdateNotifications($input: UserUpdateNotificationInput!) {
  userUpdateNotifications(input: $input)
}
` as GeneratedMutation<
  APITypes.UserUpdateNotificationsMutationVariables,
  APITypes.UserUpdateNotificationsMutation
>;
export const userMarkNotificationsReceived = /* GraphQL */ `mutation UserMarkNotificationsReceived($userID: String!) {
  userMarkNotificationsReceived(userID: $userID)
}
` as GeneratedMutation<
  APITypes.UserMarkNotificationsReceivedMutationVariables,
  APITypes.UserMarkNotificationsReceivedMutation
>;
export const userCreateVenueRecommendation = /* GraphQL */ `mutation UserCreateVenueRecommendation($input: VenueLeadInput!) {
  userCreateVenueRecommendation(input: $input)
}
` as GeneratedMutation<
  APITypes.UserCreateVenueRecommendationMutationVariables,
  APITypes.UserCreateVenueRecommendationMutation
>;
export const adminUpdateVenueRecommendation = /* GraphQL */ `mutation AdminUpdateVenueRecommendation($input: VenueLeadUpdateInput!) {
  adminUpdateVenueRecommendation(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminUpdateVenueRecommendationMutationVariables,
  APITypes.AdminUpdateVenueRecommendationMutation
>;
export const venueCreateVenue = /* GraphQL */ `mutation VenueCreateVenue($input: VenueCreateVenueInput!) {
  venueCreateVenue(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueCreateVenueMutationVariables,
  APITypes.VenueCreateVenueMutation
>;
export const venueCreatePortfolioContent = /* GraphQL */ `mutation VenueCreatePortfolioContent($input: VenueCreatePortfolioInput!) {
  venueCreatePortfolioContent(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueCreatePortfolioContentMutationVariables,
  APITypes.VenueCreatePortfolioContentMutation
>;
export const venueDeletePortfolioContent = /* GraphQL */ `mutation VenueDeletePortfolioContent($input: VenueDeletePortfolioInput!) {
  venueDeletePortfolioContent(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueDeletePortfolioContentMutationVariables,
  APITypes.VenueDeletePortfolioContentMutation
>;
export const venueRateUser = /* GraphQL */ `mutation VenueRateUser($input: VenueUserRateInput!) {
  venueRateUser(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueRateUserMutationVariables,
  APITypes.VenueRateUserMutation
>;
export const venueUpdateVenue = /* GraphQL */ `mutation VenueUpdateVenue($input: VenueUpdateVenueInput!) {
  venueUpdateVenue(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueUpdateVenueMutationVariables,
  APITypes.VenueUpdateVenueMutation
>;
export const venueUpdateEmail = /* GraphQL */ `mutation VenueUpdateEmail($input: VenueUpdateEmailInput!) {
  venueUpdateEmail(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueUpdateEmailMutationVariables,
  APITypes.VenueUpdateEmailMutation
>;
export const venueUpdatePassword = /* GraphQL */ `mutation VenueUpdatePassword($input: VenueUpdatePasswordInput!) {
  venueUpdatePassword(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueUpdatePasswordMutationVariables,
  APITypes.VenueUpdatePasswordMutation
>;
export const adminVenueBlockUser = /* GraphQL */ `mutation AdminVenueBlockUser($input: VenueBlockUserInput!) {
  adminVenueBlockUser(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminVenueBlockUserMutationVariables,
  APITypes.AdminVenueBlockUserMutation
>;
export const adminVenueUnblockUser = /* GraphQL */ `mutation AdminVenueUnblockUser($input: VenueBlockUserInput!) {
  adminVenueUnblockUser(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminVenueUnblockUserMutationVariables,
  APITypes.AdminVenueUnblockUserMutation
>;
export const venueBlockUser = /* GraphQL */ `mutation VenueBlockUser($input: VenueBlockUserInput!) {
  venueBlockUser(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueBlockUserMutationVariables,
  APITypes.VenueBlockUserMutation
>;
export const venueUnblockUser = /* GraphQL */ `mutation VenueUnblockUser($input: VenueBlockUserInput!) {
  venueUnblockUser(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueUnblockUserMutationVariables,
  APITypes.VenueUnblockUserMutation
>;
export const venueSaveUser = /* GraphQL */ `mutation VenueSaveUser($input: VenueSaveUserInput!) {
  venueSaveUser(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueSaveUserMutationVariables,
  APITypes.VenueSaveUserMutation
>;
export const venueUnsaveUser = /* GraphQL */ `mutation VenueUnsaveUser($input: VenueSaveUserInput!) {
  venueUnsaveUser(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueUnsaveUserMutationVariables,
  APITypes.VenueUnsaveUserMutation
>;
export const venueCreateOffer = /* GraphQL */ `mutation VenueCreateOffer($input: VenueCreateOfferInput!) {
  venueCreateOffer(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueCreateOfferMutationVariables,
  APITypes.VenueCreateOfferMutation
>;
export const venueCreateInvite = /* GraphQL */ `mutation VenueCreateInvite($input: VenueCreateInviteInput!) {
  venueCreateInvite(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueCreateInviteMutationVariables,
  APITypes.VenueCreateInviteMutation
>;
export const venueCreateInviteV2 = /* GraphQL */ `mutation VenueCreateInviteV2($input: VenueCreateInviteInputV2!) {
  venueCreateInviteV2(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueCreateInviteV2MutationVariables,
  APITypes.VenueCreateInviteV2Mutation
>;
export const venueDeleteInvite = /* GraphQL */ `mutation VenueDeleteInvite($input: VenueDeleteInviteInput!) {
  venueDeleteInvite(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueDeleteInviteMutationVariables,
  APITypes.VenueDeleteInviteMutation
>;
export const venueUpdateOffer = /* GraphQL */ `mutation VenueUpdateOffer($input: VenueUpdateOfferInput!) {
  venueUpdateOffer(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueUpdateOfferMutationVariables,
  APITypes.VenueUpdateOfferMutation
>;
export const venueUpdateReservationContent = /* GraphQL */ `mutation VenueUpdateReservationContent(
  $input: VenueUpdateReservationContentInput!
) {
  venueUpdateReservationContent(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueUpdateReservationContentMutationVariables,
  APITypes.VenueUpdateReservationContentMutation
>;
export const venueDownloadReservationContent = /* GraphQL */ `mutation VenueDownloadReservationContent(
  $input: VenueDownloadReservationContentInput!
) {
  venueDownloadReservationContent(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueDownloadReservationContentMutationVariables,
  APITypes.VenueDownloadReservationContentMutation
>;
export const venueDownloadReservationContentV2 = /* GraphQL */ `mutation VenueDownloadReservationContentV2(
  $input: VenueDownloadReservationContentInput!
) {
  venueDownloadReservationContentV2(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueDownloadReservationContentV2MutationVariables,
  APITypes.VenueDownloadReservationContentV2Mutation
>;
export const adminDownloadReservationContent = /* GraphQL */ `mutation AdminDownloadReservationContent(
  $input: AdminDownloadReservationContentInput!
) {
  adminDownloadReservationContent(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminDownloadReservationContentMutationVariables,
  APITypes.AdminDownloadReservationContentMutation
>;
export const venueMarkReservationContent = /* GraphQL */ `mutation VenueMarkReservationContent(
  $input: VenueMarkReservationContentInput!
) {
  venueMarkReservationContent(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueMarkReservationContentMutationVariables,
  APITypes.VenueMarkReservationContentMutation
>;
export const venueMarkReservationContentV2 = /* GraphQL */ `mutation VenueMarkReservationContentV2(
  $input: VenueMarkReservationContentInputV2!
) {
  venueMarkReservationContentV2(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueMarkReservationContentV2MutationVariables,
  APITypes.VenueMarkReservationContentV2Mutation
>;
export const venueUpdateSubscription = /* GraphQL */ `mutation VenueUpdateSubscription($input: VenueUpdateSubscriptionInput!) {
  venueUpdateSubscription(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueUpdateSubscriptionMutationVariables,
  APITypes.VenueUpdateSubscriptionMutation
>;
export const venueUpdateReservation = /* GraphQL */ `mutation VenueUpdateReservation($input: VenueUpdateReservationInput!) {
  venueUpdateReservation(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueUpdateReservationMutationVariables,
  APITypes.VenueUpdateReservationMutation
>;
export const venueUpdateNotifications = /* GraphQL */ `mutation VenueUpdateNotifications($input: VenueUpdateNotificationInput!) {
  venueUpdateNotifications(input: $input)
}
` as GeneratedMutation<
  APITypes.VenueUpdateNotificationsMutationVariables,
  APITypes.VenueUpdateNotificationsMutation
>;
export const venueMarkNotificationsReceived = /* GraphQL */ `mutation VenueMarkNotificationsReceived($venueID: String!) {
  venueMarkNotificationsReceived(venueID: $venueID)
}
` as GeneratedMutation<
  APITypes.VenueMarkNotificationsReceivedMutationVariables,
  APITypes.VenueMarkNotificationsReceivedMutation
>;
export const createFeedback = /* GraphQL */ `mutation CreateFeedback($input: CreateFeedbackInput!) {
  createFeedback(input: $input)
}
` as GeneratedMutation<
  APITypes.CreateFeedbackMutationVariables,
  APITypes.CreateFeedbackMutation
>;
export const serviceAppPutEvent = /* GraphQL */ `mutation ServiceAppPutEvent($input: AppEventInput!) {
  serviceAppPutEvent(input: $input)
}
` as GeneratedMutation<
  APITypes.ServiceAppPutEventMutationVariables,
  APITypes.ServiceAppPutEventMutation
>;
export const updateAdminConfig = /* GraphQL */ `mutation UpdateAdminConfig($input: AdminConfigInput!) {
  updateAdminConfig(input: $input)
}
` as GeneratedMutation<
  APITypes.UpdateAdminConfigMutationVariables,
  APITypes.UpdateAdminConfigMutation
>;
export const adminSendChatMessage = /* GraphQL */ `mutation AdminSendChatMessage($input: NewAdminChatMessage) {
  adminSendChatMessage(input: $input) {
    fromUserId
    toUserId
    conversationId
    userType
    messageTime
    message
    messageType
    received
    seen
    rejected
    url
    title
    thumbnail
    complaintID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AdminSendChatMessageMutationVariables,
  APITypes.AdminSendChatMessageMutation
>;
export const userSendAdminChatMessage = /* GraphQL */ `mutation UserSendAdminChatMessage($input: NewUserAdminChatMessage) {
  userSendAdminChatMessage(input: $input) {
    fromUserId
    toUserId
    conversationId
    userType
    messageTime
    message
    messageType
    received
    seen
    rejected
    url
    title
    thumbnail
    complaintID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UserSendAdminChatMessageMutationVariables,
  APITypes.UserSendAdminChatMessageMutation
>;
export const userSendChatMessageToVenue = /* GraphQL */ `mutation UserSendChatMessageToVenue($input: NewUserChatMessageToVenue) {
  userSendChatMessageToVenue(input: $input) {
    fromUserId
    toUserId
    conversationId
    userType
    messageTime
    message
    messageType
    received
    seen
    rejected
    url
    title
    thumbnail
    complaintID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UserSendChatMessageToVenueMutationVariables,
  APITypes.UserSendChatMessageToVenueMutation
>;
export const venueSendAdminChatMessage = /* GraphQL */ `mutation VenueSendAdminChatMessage($input: NewVenueAdminChatMessage) {
  venueSendAdminChatMessage(input: $input) {
    fromUserId
    toUserId
    conversationId
    userType
    messageTime
    message
    messageType
    received
    seen
    rejected
    url
    title
    thumbnail
    complaintID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.VenueSendAdminChatMessageMutationVariables,
  APITypes.VenueSendAdminChatMessageMutation
>;
export const venueSendChatMessageToUser = /* GraphQL */ `mutation VenueSendChatMessageToUser($input: NewVenueChatMessageToUser) {
  venueSendChatMessageToUser(input: $input) {
    fromUserId
    toUserId
    conversationId
    userType
    messageTime
    message
    messageType
    received
    seen
    rejected
    url
    title
    thumbnail
    complaintID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.VenueSendChatMessageToUserMutationVariables,
  APITypes.VenueSendChatMessageToUserMutation
>;
export const updateMessageStatus = /* GraphQL */ `mutation UpdateMessageStatus($input: ChatMessageUpdateInput) {
  updateMessageStatus(input: $input) {
    fromUserId
    toUserId
    conversationId
    userType
    messageTime
    message
    messageType
    received
    seen
    rejected
    url
    title
    thumbnail
    complaintID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMessageStatusMutationVariables,
  APITypes.UpdateMessageStatusMutation
>;
export const updateMessagesStatusBtwVenueAndUser = /* GraphQL */ `mutation UpdateMessagesStatusBtwVenueAndUser($input: [ChatMessageUpdateInput]) {
  updateMessagesStatusBtwVenueAndUser(input: $input) {
    fromUserId
    toUserId
    conversationId
    userType
    messageTime
    message
    messageType
    received
    seen
    rejected
    url
    title
    thumbnail
    complaintID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMessagesStatusBtwVenueAndUserMutationVariables,
  APITypes.UpdateMessagesStatusBtwVenueAndUserMutation
>;
export const updateMessageStatusBtwVenueAndUser = /* GraphQL */ `mutation UpdateMessageStatusBtwVenueAndUser($input: ChatMessageUpdateInput) {
  updateMessageStatusBtwVenueAndUser(input: $input) {
    fromUserId
    toUserId
    conversationId
    userType
    messageTime
    message
    messageType
    received
    seen
    rejected
    url
    title
    thumbnail
    complaintID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMessageStatusBtwVenueAndUserMutationVariables,
  APITypes.UpdateMessageStatusBtwVenueAndUserMutation
>;
export const updateMessagesStatus = /* GraphQL */ `mutation UpdateMessagesStatus($input: [ChatMessageUpdateInput]) {
  updateMessagesStatus(input: $input) {
    fromUserId
    toUserId
    conversationId
    userType
    messageTime
    message
    messageType
    received
    seen
    rejected
    url
    title
    thumbnail
    complaintID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMessagesStatusMutationVariables,
  APITypes.UpdateMessagesStatusMutation
>;
export const adminRejectMessage = /* GraphQL */ `mutation AdminRejectMessage($input: ChatMessageRejectInput) {
  adminRejectMessage(input: $input) {
    fromUserId
    toUserId
    conversationId
    userType
    messageTime
    message
    messageType
    received
    seen
    rejected
    url
    title
    thumbnail
    complaintID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AdminRejectMessageMutationVariables,
  APITypes.AdminRejectMessageMutation
>;
export const adminCloseChat = /* GraphQL */ `mutation AdminCloseChat($input: NewAdminCloseMessage) {
  adminCloseChat(input: $input) {
    fromUserId
    toUserId
    conversationId
    userType
    messageTime
    message
    messageType
    received
    seen
    rejected
    url
    title
    thumbnail
    complaintID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AdminCloseChatMutationVariables,
  APITypes.AdminCloseChatMutation
>;
export const userDeleteCard = /* GraphQL */ `mutation UserDeleteCard($input: UserPaymentCardDeleteRequest) {
  userDeleteCard(input: $input) {
    response
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UserDeleteCardMutationVariables,
  APITypes.UserDeleteCardMutation
>;
export const userCreateCard = /* GraphQL */ `mutation UserCreateCard($input: UserPaymentCardAddRequest) {
  userCreateCard(input: $input) {
    response
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UserCreateCardMutationVariables,
  APITypes.UserCreateCardMutation
>;
export const userCreatePayout = /* GraphQL */ `mutation UserCreatePayout($input: UserPayoutRequest!) {
  userCreatePayout(input: $input)
}
` as GeneratedMutation<
  APITypes.UserCreatePayoutMutationVariables,
  APITypes.UserCreatePayoutMutation
>;
export const venueDeleteCard = /* GraphQL */ `mutation VenueDeleteCard($input: VenuePaymentCardDeleteRequest) {
  venueDeleteCard(input: $input) {
    response
    __typename
  }
}
` as GeneratedMutation<
  APITypes.VenueDeleteCardMutationVariables,
  APITypes.VenueDeleteCardMutation
>;
export const venueCreateCard = /* GraphQL */ `mutation VenueCreateCard($input: VenuePaymentCardAddRequest) {
  venueCreateCard(input: $input) {
    response
    __typename
  }
}
` as GeneratedMutation<
  APITypes.VenueCreateCardMutationVariables,
  APITypes.VenueCreateCardMutation
>;
