import React from 'react';

import { Box } from '@mui/material';

import { useModal } from 'mui-modal-provider';

import AddBalanceDialog from '../../components/Dialogs/AddBalance/AddBalanceDialog';
import { AddFundsStripeDialog } from '../../components/Dialogs/AddFundsStripe/AddFundsStripeDialog';
import HowCollabsWorksDialog from '../../components/Dialogs/HowCollabsWorks/HowCollabsWorksDialog';
import StripeAccountVerifyDialog from '../../components/Dialogs/StripeAccountVerify/StripeAccountVerifyDialog';
import InfiniteScroll from '../../components/InfiniteScroll/InfiniteScroll';
import { BalanceHistory } from '../../components/Tables/BalanceHistory/BalanceHistory';
import { TransactionListFilter } from '../../graphql/API';
import { useVenueGetVerificationUrl } from '../../hooks/reactQuery/balance/useVenueGetVerificationUrl';
import { useVenueListAccountTransactionsQuery } from '../../hooks/reactQuery/balance/useVenueListAccountTransactionsQuery';
import {
  fetchAccountBalance,
  handleBalanceIsLoading,
} from '../../redux/features/balance/balanceSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { centsToDollars } from '../../utils/formatter';
import { BalanceSheetCard } from './components/BalanceSheetCard';
import { SuccessAlert } from './components/SuccessAlert';

export const Balance = () => {
  const { showModal } = useModal();
  const dispatch = useAppDispatch();
  const venue = useAppSelector((state) => state.venue.value);
  const { value, isLoading } = useAppSelector((state) => state.balance);
  const data = value || undefined;

  const [isPaymentSucces, setIsPaymentSucces] = React.useState(false);
  const [successAmount, setSuccessAmount] = React.useState(0);
  const [totalAmountWithFee, setTotalAmountWithFee] = React.useState(0);
  const [stripeDialog, setStripeDialog] = React.useState(false);

  const [filter, setFilter] = React.useState<TransactionListFilter>({
    sortBy: 'transactionLedger.createdAt',
    sortDirection: 'desc',
  });

  const { data: verficationData } = useVenueGetVerificationUrl(
    { venueID: venue?.id || '' },
    data?.status === 'inactive',
  );
  const {
    data: transactions,
    isFetching: transactionsIsFetching,
    isLoading: transactionsIsLoading,
    hasNextPage,
    fetchNextPage,
    refetch,
  } = useVenueListAccountTransactionsQuery({
    entityID: venue?.id,
    entityType: 'venue',
    ...filter,
  });

  const handleStripeDialog = () => {
    setStripeDialog((prev) => !prev);
  };

  const handleAddFundsDialog = () => {
    if (data?.status === 'inactive') {
      return showModal(StripeAccountVerifyDialog, {
        onVerify: () => {
          return window.open(verficationData?.onBoardingURL, '_self');
        },
      });
    }

    showModal(AddBalanceDialog, {
      currency: venue?.currency || '',
      onSubmit: (totalAmountWithFee) => {
        setTotalAmountWithFee(totalAmountWithFee as number);
        handleStripeDialog();
      },
      getOriginalPriceHandle: (amount) => {
        setSuccessAmount(amount as number);
      },
    });
  };

  const handlerLearnMoreAboutDialog = () => {
    showModal(HowCollabsWorksDialog, {});
  };

  return (
    <Box
      height='100%'
      sx={{
        backgroundColor: '#ffffff',
        paddingY: '74px',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
      }}
    >
      <Box sx={{ width: '75%', margin: '0 auto' }}>
        <SuccessAlert
          amount={successAmount}
          currency={venue?.currency || ''}
          isOpen={isPaymentSucces}
          onClose={() => {
            setIsPaymentSucces(false);
          }}
        />
        <BalanceSheetCard
          currency={venue?.currency || ''}
          balance={centsToDollars(data?.balance || 0)}
          isInActive={data?.status === 'inactive'}
          onAddFunds={handleAddFundsDialog}
          isLoading={isLoading || false}
          onLearnMoreAbout={handlerLearnMoreAboutDialog}
        />

        <BalanceHistory
          data={transactions}
          filter={filter}
          setFilter={setFilter}
          isLoading={transactionsIsLoading}
          isFetching={transactionsIsFetching}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
        />
      </Box>

      <AddFundsStripeDialog
        open={stripeDialog}
        onClose={handleStripeDialog}
        amount={totalAmountWithFee}
        amountWithoutFee={successAmount}
        onSuccess={() => {
          dispatch(handleBalanceIsLoading());
          setTimeout(() => {
            setIsPaymentSucces(true);
            dispatch(fetchAccountBalance({ venueID: venue?.id || '' }));
            refetch();
          }, 5000);
        }}
      />
    </Box>
  );
};
