import React from 'react';
import { Link } from 'react-router-dom';

import { Avatar, Box, Typography } from '@mui/material';

import styled from '@emotion/styled';

import { Reservation } from '../../graphql/API';
import { useRequirementOfReservation } from '../../pages/OfferDetail/components/useRequirment';
import { getDateAndTimeInTimezoneInDayjs } from '../../utils/date';

type Props = {
  reservationDetail?: Reservation;
};

export const OfferRequirement: React.FC<Props> = ({ reservationDetail }) => {
  const requirements = useRequirementOfReservation(reservationDetail);
  return (
    <RequirementContainer>
      <RequirementItem showBorderBottom>
        <Avatar src={reservationDetail?.offerPicture?.thumbnail || ''} alt='offer-thumbnail' />
        <Box>
          <RequirementTitle>Reservation</RequirementTitle>
          <Box display={'flex'} alignItems={'flex-end'} gap={'8px'}>
            <Typography
              component={Link}
              to={`/reservations/${reservationDetail?.id}`}
              sx={{
                color: '#111',
                fontSize: '15px',
                fontWeight: 500,
              }}
            >
              {reservationDetail?.offerTitle}
            </Typography>
            {reservationDetail?.effectiveStartDateTime && (
              <Typography sx={{ fontSize: '15px', color: '#111' }}>
                {getDateAndTimeInTimezoneInDayjs(
                  reservationDetail?.reservationDate,
                  reservationDetail?.timeZoneId,
                )?.format('ll')}
              </Typography>
            )}
          </Box>
        </Box>
      </RequirementItem>
      <RequirementItem>
        {requirements.map(({ Icon, label }, i) => (
          <>
            <Icon sx={{ width: '30px', height: '30px' }} />
            <Box key={`requirement-${i}`} display='flex' alignItems='flex-start' sx={{ mx: 1 }}>
              <Box>
                <RequirementTitle>Requirement</RequirementTitle>
                <Typography variant='body2'>{label}</Typography>
              </Box>
            </Box>
          </>
        ))}
      </RequirementItem>
    </RequirementContainer>
  );
};

const RequirementContainer = styled.div`
  min-width: 400px;
  border-radius: 8px;
  border: 1px solid #ebe8e8;
  background: #fff;
  padding: 0px 15px;
  margin: 12px 0;
`;

const RequirementItem = styled.div<{ showBorderBottom?: boolean }>`
  padding: 12px 0;
  border-bottom: ${(props) => (props.showBorderBottom ? '1px solid #ebe8e8' : '')};

  display: flex;
  align-items: center;
  gap: 8px;
`;

const RequirementTitle = styled.h6`
  color: #949ba5;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  padding: 0;
  margin: 0;
  text-transform: capitalize;
`;
