import * as React from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
  debounce,
} from '@mui/material';

import styled from '@emotion/styled';
import { ShowFnOutput, useModal } from 'mui-modal-provider';

import Button from '../../components/Button/Button';
import SavedButton from '../../components/Button/SavedButton';
import { InviteDialog } from '../../components/Dialogs';
import CollabsFiltersDialog from '../../components/Dialogs/CollabsFilters/CollabsFiltersDialog';
import CollabsSentDialog from '../../components/Dialogs/CollabsSent/CollabsSentDialog';
import { InviteDialogProps } from '../../components/Dialogs/CreateInvite/InviteDialog';
import HowCollabsWorksDialog from '../../components/Dialogs/HowCollabsWorks/HowCollabsWorksDialog';
import TitleWithIcon from '../../components/Dialogs/TitleWithIcon';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useNotificationsContext } from '../../contexts/NotificationsContext';
import { VenueListUsersFilter } from '../../graphql/API';
import { useVenueListUserEstimatedPriceQuery } from '../../hooks/reactQuery/balance/useVenueListUserEstimatedPriceQuery';
import { useLocalStorageState } from '../../hooks/useLocalStorageState';
import { markHighlight } from '../../redux/features/invite/inviteSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import Icons from '../../themes/icons';
import InviteIcon from './InviteIcon';
import InviteSentButton from './components/InviteSentButton';
import { CollabReducer } from './reducer';
import { formatNumberWithCommas } from '../../utils/formatter';

type HeaderProps = {
  reducer: CollabReducer;
  inScroll: boolean;
};

type CountProps = {
  count: number;
};
const Count: React.FC<CountProps> = ({ count }) => (
  <Box component='span' sx={{ color: count > 0 ? '#BA8AFF' : undefined }}>
    {count}
  </Box>
);

const Header: React.FC<HeaderProps> = ({ reducer, inScroll }) => {
  const [collabState, dispatch] = reducer;
  const {
    filters,
    selected: { users: selectedUsers },
  } = collabState;

  const inviteModalRef = React.useRef<ShowFnOutput<InviteDialogProps>>();

  const { showModal } = useModal();
  const { lastCollabUpdatedAtTime, invite, handleUpdateInvite } = useNotificationsContext() || {};

  const highlight = useAppSelector((state) => state.invite.highlight);
  const appDispatch = useAppDispatch();
  const [searchText, setSearchText] = useLocalStorageState('collab-search-text', '');

  const { data, isFetching: isFetchingEstimatedPrice } = useVenueListUserEstimatedPriceQuery({
    userIDs: selectedUsers.map((user) => Number(user.id || 0)),
  });

  const totalMinEstimatedCost = React.useMemo(
    () => (data ? data.reduce<number>((acc, val) => acc + (val.estimatedPriceMin || 0), 0) : 0),
    [data],
  );

  const totalMaxEstimatedCost = React.useMemo(
    () => (data ? data.reduce<number>((acc, val) => acc + (val.estimatedPriceMax || 0), 0) : 0),
    [data],
  );

  const searchDebounce = React.useMemo(
    () =>
      debounce(
        (searchText: string) => dispatch({ type: 'SET_FILTERS', payload: { searchText } }),
        500,
      ),
    [dispatch],
  );

  React.useEffect(() => {
    searchDebounce(searchText);
  }, [searchText, searchDebounce]);

  const selected = React.useMemo(
    () => ({
      count: selectedUsers.length,
    }),
    [selectedUsers],
  );

  const handleCreateInviteDialog = React.useCallback(() => {
    inviteModalRef.current = showModal(InviteDialog, {
      title: 'Invite',
      users: selectedUsers,
      modalRef: inviteModalRef,
      onSuccess: () => {
        appDispatch(markHighlight(true));
        dispatch({ type: 'RESET_SELECTED_USERS' });
      },
    });
  }, [selectedUsers, showModal, dispatch, appDispatch]);

  const handleFilterDialog = React.useCallback(
    () =>
      showModal(CollabsFiltersDialog, {
        titleComponent: <TitleWithIcon title='Filters' icon={<TuneOutlinedIcon />} />,
        onFilterChange: (f) => dispatch({ type: 'SET_FILTERS', payload: f }),
        filters,
      }),
    [filters, dispatch, showModal],
  );

  const handleHowCollabsWorksDialog = () => {
    showModal(HowCollabsWorksDialog, {});
  };

  const handleCollabSentInviteDialog = React.useCallback(() => {
    showModal(CollabsSentDialog, {
      handleUpdateLastInvite: handleUpdateInvite,
    });
  }, [handleUpdateInvite, showModal]);

  const handleTextOnChange = React.useCallback<React.ChangeEventHandler<HTMLTextAreaElement>>(
    (e) => setSearchText(e.target.value),
    [setSearchText],
  );

  const handleClick = React.useCallback(() => {
    dispatch({ type: 'SET_FILTERS', payload: { saved: filters?.saved ? undefined : true } });
  }, [filters, dispatch]);

  const isAnyFilterActive = React.useCallback(
    (filters: Partial<VenueListUsersFilter> | undefined) => {
      return (
        filters &&
        ((filters['minFollowers'] && filters['minFollowers'] > 0) ||
          filters['lifestyle'] !== undefined ||
          filters['modelType'] !== undefined ||
          filters['instagram'] !== undefined)
      );
    },
    [],
  );

  const handleClearSearchText = React.useCallback(() => {
    setSearchText('');
  }, [setSearchText]);

  return (
    <Box
      sx={{
        position: 'sticky',
        backgroundColor: 'white',
        p: 4,
        ...(inScroll ? { boxShadow: '0px 1px 20px #00000026' } : undefined),
      }}
    >
      <PageTitle
        titleComponent={
          <Box display={'flex'} alignItems={'center'}>
            <Typography variant='h5' fontWeight='bold' mr={3}>
              Collabs
            </Typography>
            <LearnMoreContainer onClick={handleHowCollabsWorksDialog}>
              <IconButton size='small' color='primary'>
                <Icons.InfoCircleFillIcon style={{ width: '17px', height: '17px', fill: '#111' }} />
              </IconButton>
              <LearnMoreButtonText>Learn more</LearnMoreButtonText>
            </LearnMoreContainer>
          </Box>
        }
        rightComponent={
          <Stack direction='row' spacing={2}>
            <SavedButton onClick={handleClick} active={Boolean(filters?.saved)}>
              Saved
            </SavedButton>
            <Button
              id='btn-filter'
              startIcon={
                <TuneOutlinedIcon htmlColor={isAnyFilterActive(filters) ? '#765BC6' : ''} />
              }
              onClick={handleFilterDialog}
            >
              <span style={{ color: isAnyFilterActive(filters) ? '#765BC6' : '' }}>Filters</span>
            </Button>
            <InviteSentButton
              id='btn-list-invite'
              highlight={
                highlight ||
                (lastCollabUpdatedAtTime && invite?.updatedAt !== lastCollabUpdatedAtTime)
                  ? true
                  : false
              }
              onClick={handleCollabSentInviteDialog}
            />
            <Stack direction='row' spacing={1}>
              <OutlinedInput
                id='outlined-adornment-amount'
                placeholder='Search a member...'
                startAdornment={
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                }
                endAdornment={
                  searchText && (
                    <InputAdornment position='end'>
                      <ClearIcon cursor='pointer' onClick={handleClearSearchText} />
                    </InputAdornment>
                  )
                }
                defaultValue={filters?.searchText}
                value={searchText}
                onChange={handleTextOnChange}
                size='small'
              />
              <Button
                id='btn-create-invite'
                variant='contained'
                disabled={!selectedUsers.length}
                sx={{ width: 140 }}
                onClick={handleCreateInviteDialog}
                startIcon={<InviteIcon active={Boolean(selected.count)} />}
              >
                Invite (<Count count={selected.count} />)
              </Button>
            </Stack>
          </Stack>
        }
      />

      <Typography component={Stack} spacing={3} direction='row' mt={1} alignItems={'center'}>
        <Box component='span' fontWeight='medium'>
          {selected.count} Selected member
        </Box>
        <Button
          sx={{
            p: 0,
            lineHeight: 'unset',
            minWidth: 'unset',
            textDecoration: 'underline',
            ':hover': { backgroundColor: 'unset', textDecoration: 'underline' },
          }}
          onClick={() => {
            dispatch({ type: 'RESET_SELECTED_USERS' });
            dispatch({ type: 'RESET_SELECTED_FILTERS' });
          }}
        >
          Reset
        </Button>

        {selected.count > 0 && (
          <>
            <Divider orientation='vertical' flexItem />
            <Box component='div' display={'flex'} alignItems={'center'} gap={'10px'}>
              <EstimatedCost>Post estimate:</EstimatedCost>
              {isFetchingEstimatedPrice ? (
                <CircularProgress size={14} sx={{ color: '#a9a9a9' }} />
              ) : (
                <EstimatedCost>
                  ${formatNumberWithCommas(totalMinEstimatedCost)} - ${formatNumberWithCommas(totalMaxEstimatedCost)}
                </EstimatedCost>
              )}
            </Box>
          </>
        )}
      </Typography>
    </Box>
  );
};

export default Header;

const LearnMoreContainer = styled.div`
  padding: 5px 11px;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.03);

  display: flex;
  align-items: center;
  gap: 4px;

  cursor: pointer;
`;

const LearnMoreButtonText = styled.div`
  color: #111;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
`;

const EstimatedCost = styled.span`
  display: inline-block;
  color: #111;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
`;

{
  /* {subscriptionPlan === 'plus' ? null : (
  <>
    {venueCollabEstimateData?.totalPremiumCredits &&
      venueCollabEstimateData?.totalNonPremiumCredits &&
      venueCollabEstimateData.totalPremiumCredits <
        venueCollabEstimateData.totalNonPremiumCredits && (
        <Box
          sx={{
            paddingY: '6px',
            paddingX: '10px',
            border: '1px solid #E28DDE',
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '10px',
          }}
        >
          <Typography
            fontSize='15px'
            color='#a9a9a9'
            sx={{ textDecoration: 'line-through' }}
          >
            {selected.cost} credits
          </Typography>
          <Typography fontSize='15px'>
            {veneuCollabEstimateIsFetching ? (
              <CircularProgress size={14} sx={{ color: '#E28DDE' }} />
            ) : (
              venueCollabEstimateData?.totalPremiumCredits
            )}
            &nbsp;credits with
          </Typography>
          <Box display={'flex'} alignItems={'center'} gap={'4px'}>
            <SvgIcon name='Plus' sx={{ fill: 'url(#linear-gradient)' }} fontSize='small' />
            <Typography
              component={Link}
              fontSize='15px'
              color='#E28DDE'
              fontWeight={'600'}
              sx={{ textDecoration: 'underline' }}
              to={'/plans'}
            >
              Plus
            </Typography>
          </Box>
        </Box>
      )}
  </>
)} */
}
